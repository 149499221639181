import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Paper, Typography, Box, Button,ToggleButton, ToggleButtonGroup } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { Product } from '../../store/app/user/types';
import { getRetrieveSubscriptionData, getProductList, addSubscription } from '../../store/app/user/actions';
import { useLocation } from 'react-router-dom';
import FeaturesModal from '../components/featureModal';
import InfoIcon from '@mui/icons-material/Info'; // Import the InfoIcon


function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const AddSubscriptionMobile: React.FC = () => {
    // const [billingCycle, setBillingCycle] = useState<'monthly' | 'yearly'>('yearly');
    // const { userRetrieveSubscriptionData, productList } = useSelector(
    //     (state: ApplicationState) => state.user
    // )
    // const [modalOpen, setModalOpen] = useState(false);
    // const [modalFeatures, setModalFeatures] = useState<string[]>([]);

    // const dispatch = useDispatch()

    

    // let query = useQuery();
    // const user_id = Number(query.get('user_id'));
    // const user_type = Number(query.get('user_type'));
    // const email = query.get('email');
    // const name = query.get('name');
    // const token = query.get('token');

    // const handleBillingCycleChange = (event: React.MouseEvent<HTMLElement>, newCycle: 'monthly' | 'yearly') => {
    //     if (newCycle !== null) {
    //         setBillingCycle(newCycle);
    //     }
    // };

    // const featuresMap: { [key: string]: string[] } = {
    //     "Tsekmo Combo: Basic Monetization & Anti-vandalism": [
    //         "Bill now",
    //         "Bill the bathroom vandal",
    //         "Block the vandal for non-payment",
    //         "Resolve the issue or auto resolve once payment received",
    //         "Monetize your Bathroom",
    //         "Set up your $ per use from 0.99-9.99",
    //         "Set up your discount list including paying customers",
    //         "Unlimited Chat with attachment enabled",
    //     ],
    //     "Tsekmo: Anti-Vandalism Bathroom App": [
    //         "Access to Bill Now and Bill list",
    //         "Bill the bathroom vandal",
    //         "Resolve issue or auto resolve once payment received",
    //         "Block the bathroom vandal for non-payment",
    //         "Unlimited Chat with attachment enabled",
    //     ],
    //     "Tsekmo: Basic Monetization": [
    //         "Monetize your Bathroom",
    //         "Set up your discount list including paying customers",
    //         "Set up your $ per use from 0.99-9.99 for your bathroom users",
    //         "Unlimited Chat with attachment enabled",
    //     ],
    // };

    // const productPatterns: { [key: string]: RegExp } = {
    //     "Tsekmo Combo: Basic Monetization & Anti-vandalism": /Tsekmo Combo: Basic Monetization & Anti-vandalism/i,
    //     "Tsekmo: Anti-Vandalism Bathroom App": /Tsekmo: Anti-Vandalism Bathroom App/i,
    //     "Tsekmo: Basic Monetization": /Tsekmo: Basic Monetization/i,
    // };

    // useEffect(() => {
    //     // dispatch(getProductList(token!, 18, user_type));
    //     dispatch(getRetrieveSubscriptionData(user_id, token!))
    // }, [dispatch, token, user_id, user_type])


    // if (!productList || !productList[billingCycle]) {
    //     return null;
    // }

    // const handleLearnMore = (features: string[]) => {
    //     setModalFeatures(features);
    //     setModalOpen(true);
    // };

    // const currentSubscriptions = userRetrieveSubscriptionData?.subscriptionDataArray || [];
    // const currentPlanIds = currentSubscriptions.map((sub: any) => sub.getSubscriptionData.items.data[0].plan.id);

    // const handleUpgrade = (item: any) => {
    //     const data = {
    //         user_id: user_id,
    //         unit_amount: item.unit_amount,
    //         price_id: item.id,
    //         email: email,
    //         name: name,
    //         package_quantity: 1,
    //         interval: item.recurring.interval,
    //         token: token
    //     };
    //     dispatch(addSubscription(data));
    // }

    // // Check if the user has a subscription to either Anti-Vandalism or Basic Monetization
    // const hasAntiVandalism = currentSubscriptions.some((sub: any) =>
    //     productPatterns["Tsekmo: Anti-Vandalism Bathroom App"].test(sub.getSubscriptionData.items.data[0].plan.nickname)
    // );
    // const hasBasicMonetization = currentSubscriptions.some((sub: any) =>
    //     productPatterns["Tsekmo: Basic Monetization"].test(sub.getSubscriptionData.items.data[0].plan.nickname)
    // );

    // const shouldHideCombo = hasAntiVandalism || hasBasicMonetization;

    // const renderProductGridItem = (product: Product) => {
    //     const isSubscribed = currentPlanIds.includes(product.id);
    //     const getProductFeatures = (nickname: string): string[] => {
    //         for (const [key, pattern] of Object.entries(productPatterns)) {
    //             if (pattern.test(nickname)) {
    //                 return featuresMap[key];
    //             }
    //         }
    //         return [];
    //     };
    
    //     const features = getProductFeatures(product.nickname);

    //     if (shouldHideCombo && productPatterns["Tsekmo Combo: Basic Monetization & Anti-vandalism"].test(product.nickname)) {
    //         return null;
    //     }
    //     return (
    //         <Grid item key={product.id} xs={12} sm={6} md={4}>
    //             <Paper
    //                 elevation={3}
    //                 sx={{
    //                     padding: 3,
    //                     backgroundColor: isSubscribed ? 'rgba(255, 255, 255, 0.1)' : 'inherit', // White transparent background for subscribed
    //                     border: isSubscribed ? '2px solid rgba(255, 255, 255, 0.5)' : 'none', // White transparent border for subscribed
    //                     height: '100%', // Set the height to 100% to ensure all cards have the same height
    //                     display: 'flex',
    //                     flexDirection: 'column',
    //                     justifyContent: 'space-between',
    //                     borderRadius: 5,
    //                     boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    //                 }}
    //             >
    //                 <Box sx={{ flex: 1 }}>
    //                     <Typography variant="h5" gutterBottom sx={{color: '#fff', fontWeight: 'bold'}}>
    //                         {product.nickname}
    //                     </Typography>
    //                     <Typography variant="h6" gutterBottom sx={{color: '#fff'}}>
    //                         ${(product.unit_amount / 100).toFixed(2)}
    //                     </Typography>
    //                     <Typography variant="subtitle1" gutterBottom  sx={{color: '#fff'}}>
    //                         {product.recurring.interval === 'year' ? 'per year' : 'per month'}
    //                     </Typography>
    //                 </Box>
    //                 {!isSubscribed && (
    //                     <>
    //                         <Button 
    //                             size="small"
    //                             variant="contained"
    //                             sx={{
    //                                 mt: 2,
    //                                 backgroundColor: 'rgb(30, 28, 120)', // Background color
    //                                 color: 'white', // Text color
    //                                 '&:hover': {
    //                                     backgroundColor: 'rgba(30, 28, 120, 0.8)', // Slightly lighter color on hover
    //                                 },
    //                                 borderRadius: 5,
    //                                 justifyContent: 'center',
    //                             }}
    //                             onClick={() => handleUpgrade(product)}>
    //                             Add Subscription
    //                         </Button>
    //                         <Box sx={{ mt: 2,color: '#fff'}}>
    //                             <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
    //                                 This includes:
    //                             </Typography>
    //                             <ul style={{ paddingLeft: '20px', marginTop: 0 }}>
    //                                 {features.map((feature, index) => (
    //                                     <li key={index} style={{ marginBottom: '8px', listStyleType: 'none', display: 'flex', alignItems: 'center' }}>
    //                                         <CheckCircle sx={{ marginRight: '4px', fontSize: '16px' }} />
    //                                         <Typography variant="body2">{feature}</Typography>
    //                                     </li>
    //                                 ))}
    //                             </ul>
    //                         </Box>
    //                     </>
    //                 )}
    //             </Paper>
    //         </Grid>
    //     )
    // }

    return (
        <Box sx={{ padding: 4, backgroundColor: 'rgb(16, 78, 234)', minHeight: '100vh' }}>
            {/* <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 4 }}>
                <ToggleButtonGroup
                    value={billingCycle}
                    exclusive
                    onChange={handleBillingCycleChange}
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        borderRadius: 5,
                        '& .MuiToggleButton-root': {
                            color: '#fff',
                            borderColor: '#fff',
                            '&.Mui-selected': {
                                backgroundColor: 'rgba(30,28,120,1.000)',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                },
                                color: '#fff',
                            },
                            borderRadius: 5,
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            },
                        },
                    }}
                >
                    <ToggleButton value="monthly">Monthly</ToggleButton>
                    <ToggleButton value="yearly">Yearly</ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid container spacing={3} sx={{ width: '100%', maxWidth: '1200px' }}>
                    {productList[billingCycle].map(renderProductGridItem)}
                </Grid>
            </Box>
            {/* <FeaturesModal
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
                features={modalFeatures}
            /> */} 
        </Box>
    );
};

export default AddSubscriptionMobile;