import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  List,
  DialogContent,
  ListItem,
  ListItemText,
  Checkbox,
  DialogActions,
  Chip,
  Paper,
  Avatar,
  useTheme,
} from "@mui/material";
import { CheckCircleOutline, WarningAmber, ArrowForward } from "@mui/icons-material";
import { fetchUser, getAffiliate, saveAffiliateAgreement } from '../../../../store/app/user/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'
import { UserData } from "../../../../store/app/user/types";
import TermsModal from "../components/modals/TermsModal";
import moment from "moment";

interface PayoutStatus {
  registration: 'pending' | 'eligible' | 'paid'
  subscription: 'pending' | 'eligible' | 'paid'
}

interface Affiliate {
  id: string
  username: string
  user_type: number
  package_subscribed: string | null
  account_created: string
  subscription_created: string | null
  registration_payout_date: string | null
  subscription_payout_date: string | null
  payout_status: PayoutStatus
  stripe_account_connected: string
  tsekmo_packages_subscribe: string
  total_earnings: number
}

const Affiliates: React.FC = () => {
  const theme = useTheme();
  const [selectedPayouts, setSelectedPayouts] = useState<Record<string, string[]>>({})
  const [payoutModalOpen, setPayoutModalOpen] = useState(false)
  const [currentAffiliate, setCurrentAffiliate] = useState<Affiliate | null>(null)
  const [affiliates, setAffiliates] = useState<Affiliate[]>([])
  const [activateModalOpen, setActivateModalOpen] = useState(false)

  const dispatch = useDispatch()
  const { affiliateData, userData } = useSelector(
    (state: ApplicationState) => state.user
  ) as {
    affiliateData: Affiliate[];
    userData: UserData;
  }

  useEffect(() => {
    if (userData?.user_info?.affiliate_code) {
      dispatch(getAffiliate(userData.user_info.affiliate_code))
    }
  }, [userData, dispatch])

  useEffect(() => {
    if (affiliateData && Array.isArray(affiliateData)) {
      setAffiliates(affiliateData)
      console.log(`Check affiliateData : ${JSON.stringify(affiliateData, null, 2)}`)
    }
  }, [affiliateData])

  const handlePayoutSelection = (affiliateId: string, payoutType: string) => {
    setSelectedPayouts(prev => ({
      ...prev,
      [affiliateId]: prev[affiliateId]?.includes(payoutType)
        ? prev[affiliateId].filter(t => t !== payoutType)
        : [...(prev[affiliateId] || []), payoutType]
    }))
  }

  const handlePayoutRequest = (affiliate: Affiliate) => {
    setCurrentAffiliate(affiliate)
    setPayoutModalOpen(true)
  }

  const handleActivateSubmit = (legalName: string) => {
    const data ={
      user_id: userData.id,
      affiliate_name: legalName
    }
    
    dispatch(saveAffiliateAgreement(data));
    
    dispatch(fetchUser({user_id: userData.id}))

    setActivateModalOpen(false)
  }

  return (
    <Box sx={{ 
      padding: "40px",
      maxWidth: '1440px',
      margin: '0 auto',
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default,
    }}>
      {userData?.user_info?.affiliate_agreement ? (
        <>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4
          }}>
            <Typography variant="h3" sx={{
              fontWeight: 700,
              color: theme.palette.primary.main,
              fontSize: '2.5rem'
            }}>
              Affiliates Dashboard
            </Typography>
            <Chip 
              label="Active Account" 
              color="success" 
              icon={<CheckCircleOutline />}
              variant="outlined"
            />
          </Box>

          <Box sx={{ 
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
            gap: 3,
            mb: 4
          }}>
            <Paper sx={{ p: 3, borderRadius: '12px' }}>
              <Typography variant="body2" color="textSecondary">Total Affiliates</Typography>
              <Typography variant="h4">{affiliates.length}</Typography>
            </Paper>
            <Paper sx={{ p: 3, borderRadius: '12px' }}>
              <Typography variant="body2" color="textSecondary">Payout Request</Typography>
              <Typography variant="h4">
               1
              </Typography>
            </Paper>
            <Paper sx={{ p: 3, borderRadius: '12px' }}>
              <Typography variant="body2" color="textSecondary">Total Earnings</Typography>
              <Typography variant="h4">
                ${affiliates.reduce((total, affiliate) => total + (affiliate.total_earnings || 0), 0)}
              </Typography>
            </Paper>
          </Box>

          <Card sx={{
            borderRadius: '16px',
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.08)',
            overflow: 'hidden'
          }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead sx={{ 
                  backgroundColor: theme.palette.grey[50],
                  borderBottom: `2px solid ${theme.palette.divider}`
                }}>
                  <TableRow>
                    {['Username', 'Registration Payout', 'Subscription Payout', 'Status', 'Actions'].map((header) => (
                      <TableCell key={header} sx={{
                        py: 3,
                        fontSize: '0.875rem',
                        fontWeight: 600,
                        color: theme.palette.text.secondary
                      }}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {affiliates.map((affiliate) => {
                    const registrationDate = affiliate.registration_payout_date 
                    ? moment(affiliate.registration_payout_date).utc().format('MM/DD/YYYY')
                    : 'N/A';
                    
                    const subscriptionDate = affiliate.subscription_payout_date 
                      ? moment(affiliate.subscription_payout_date).utc().format('MM/DD/YYYY')
                      : 'N/A';

                    return (
                      <TableRow 
                        key={affiliate.id}
                        hover
                        sx={{ '&:last-child td': { borderBottom: 0 } }}
                      >
                        <TableCell sx={{ fontWeight: 500 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Avatar sx={{ 
                              width: 32, 
                              height: 32,
                              bgcolor: theme.palette.primary.light,
                              color: theme.palette.primary.main
                            }}>
                              {affiliate.username[0]}
                            </Avatar>
                            {affiliate.username}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {registrationDate ? 
                            `${registrationDate} - ${affiliate.payout_status.registration}` : 
                            'N/A'}
                        </TableCell>
                        <TableCell>
                          {subscriptionDate ? 
                            `${subscriptionDate} - ${affiliate.payout_status.subscription}` : 
                            'N/A'}
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={Object.values(affiliate.payout_status).includes('eligible') 
                              ? 'Eligible' 
                              : 'Pending'}
                            color={Object.values(affiliate.payout_status).includes('eligible') 
                              ? 'success' 
                              : 'warning'}
                            variant="outlined"
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            endIcon={<ArrowForward />}
                            disabled={!Object.values(affiliate.payout_status).includes('eligible')}
                            onClick={() => handlePayoutRequest(affiliate)}
                            sx={{
                              borderRadius: '8px',
                              textTransform: 'none',
                              py: 1,
                              px: 3,
                              boxShadow: 'none',
                              backgroundColor: `${theme.palette.primary.main} !important`,
                              '&:hover': {
                                transform: 'translateY(-1px)',
                                boxShadow: `0 4px 12px ${theme.palette.primary.light}`,
                                backgroundColor: `${theme.palette.primary.dark} !important`,
                              },
                              transition: 'all 0.2s ease'
                            }}
                          >
                            Request Payout
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </>
      ) : (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '60vh',
          textAlign: 'center',
          p: 4,
          background: `linear-gradient(135deg, ${theme.palette.grey[50]} 0%, ${theme.palette.background.default} 100%)`,
          borderRadius: '24px',
          border: `1px solid ${theme.palette.divider}`
        }}>
          <WarningAmber sx={{ 
            fontSize: '64px', 
            color: theme.palette.warning.main,
            mb: 2
          }} />
          <Typography variant="h4" sx={{ 
            fontWeight: 700,
            mb: 2,
            color: theme.palette.text.primary
          }}>
            Activate Your Affiliate Account
          </Typography>
          <Typography variant="body1" sx={{ 
            maxWidth: '600px',
            mb: 4,
            color: theme.palette.text.secondary
          }}>
            Unlock exclusive benefits and start earning commissions by activating your affiliate account today. 
            Get access to marketing materials, tracking tools, and real-time analytics.
          </Typography>
          <Button 
            variant="contained" 
            onClick={() => setActivateModalOpen(true)}
            sx={{
              borderRadius: '12px',
              px: 6,
              py: 2,
              fontSize: '1.1rem',
              textTransform: 'none',
              color: 'black',
              boxShadow: `0 4px 16px ${theme.palette.primary.light}`,
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: `0 6px 20px ${theme.palette.primary.dark}`,
                color: 'white'
              },
              transition: 'all 0.3s ease'
            }}
          >
            Get Started
          </Button>
        </Box>
      )}

      {/* Activation Modal */}
      <TermsModal 
        open={activateModalOpen}
        onClose={() => setActivateModalOpen(false)}
        onAccept={handleActivateSubmit}
        theme={theme}
      />

      {/* Payout Modal */}
      <Dialog open={payoutModalOpen} onClose={() => setPayoutModalOpen(false)}>
        <DialogTitle sx={{ 
          fontSize: '1.25rem',
          fontWeight: 600,
          bgcolor: theme.palette.grey[50],
          borderBottom: `1px solid ${theme.palette.divider}`
        }}>
          Select Payouts to Claim
        </DialogTitle>
        <DialogContent sx={{ py: 3 }}>
          {currentAffiliate && (
            <List>
              <ListItem>
                <ListItemText
                  primary="Registration Payout"
                  secondary={currentAffiliate.registration_payout_date 
                    ? `${new Date(currentAffiliate.registration_payout_date).toLocaleDateString()} - $30.00`
                    : 'N/A'}
                />
                <Checkbox
                  disabled={currentAffiliate.payout_status.registration !== 'eligible'}
                  checked={selectedPayouts[currentAffiliate.id]?.includes('registration') || false}
                  onChange={() => handlePayoutSelection(currentAffiliate.id, 'registration')}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Subscription Payout"
                  secondary={currentAffiliate.subscription_payout_date 
                    ? `${new Date(currentAffiliate.subscription_payout_date).toLocaleDateString()} - $25.00`
                    : 'N/A'}
                />
                <Checkbox
                  disabled={currentAffiliate.payout_status.subscription !== 'eligible'}
                  checked={selectedPayouts[currentAffiliate.id]?.includes('subscription') || false}
                  onChange={() => handlePayoutSelection(currentAffiliate.id, 'subscription')}
                />
              </ListItem>
            </List>
          )}
        </DialogContent>
        <DialogActions sx={{ 
          px: 3,
          py: 2,
          borderTop: `1px solid ${theme.palette.divider}`
        }}>
          <Button 
            onClick={() => setPayoutModalOpen(false)}
            sx={{ 
              borderRadius: '8px',
              px: 3,
              textTransform: 'none'
            }}
          >
            Cancel
          </Button>
          <Button 
            variant="contained" 
            onClick={() => {
              console.log('Processing payouts:', selectedPayouts[currentAffiliate?.id || '']);
              setPayoutModalOpen(false);
            }}
            sx={{
              borderRadius: '8px',
              px: 4,
              textTransform: 'none',
              fontWeight: 600,
              boxShadow: 'none',
              backgroundColor: `${theme.palette.primary.main} !important`,
              '&:hover': {
                boxShadow: `0 4px 12px ${theme.palette.primary.light}`,
                backgroundColor: `${theme.palette.primary.dark} !important`,
              }
            }}
          >
            Confirm Payout
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Affiliates