import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    useTheme,
  } from "@mui/material";
const ConfirmationModal: React.FC<{
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
    confirmText?: string;
    cancelText?: string;
  }> = ({ open, title, message, onConfirm, onCancel, confirmText = 'Confirm', cancelText = 'Cancel' }) => {
    const theme = useTheme();
    
    return (
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            {cancelText}
          </Button>
          <Button onClick={onConfirm} color="primary" variant="contained" sx={{
              borderRadius: '8px',
              px: 4,
              textTransform: 'none',
              fontWeight: 600,
              boxShadow: 'none',
              backgroundColor: `${theme.palette.primary.main} !important`,
              '&:hover': {
                boxShadow: `0 4px 12px ${theme.palette.primary.light}`,
                backgroundColor: `${theme.palette.primary.dark} !important`,
              }
            }} autoFocus>
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  export default ConfirmationModal