import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Paper, Typography, Box, Button,ToggleButton, ToggleButtonGroup } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { Product } from '../../store/app/user/types';
import { getRetrieveSubscriptionData, getProductList, addSubscription } from '../../store/app/user/actions';
import { useLocation } from 'react-router-dom';

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const AppSubscription: React.FC = () => {
    // const [billingCycle, setBillingCycle] = useState<'monthly' | 'yearly'>('yearly');
    // const { userRetrieveSubscriptionData, productList } = useSelector(
    //     (state: ApplicationState) => state.user
    // )

    // const dispatch = useDispatch()

    // let query = useQuery();
    // const user_id = Number(query.get('user_id'));
    // const is_app_subscription = Boolean(query.get('is_app_subscription'));
    // const email = query.get('email');
    // const name = query.get('name');
    // const token = query.get('token');

    // const handleBillingCycleChange = (event: React.MouseEvent<HTMLElement>, newCycle: 'monthly' | 'yearly') => {
    //     if (newCycle !== null) {
    //         setBillingCycle(newCycle);
    //     }
    // };

    // const featuresMap: { [key: string]: string[] } = {
    //     "Chifwash": [
    //         "Real-Time Cleaning Logs: Track every cleaning session instantly.",
    //         "Photo Documentation: Capture proof of cleanliness with time-stamped photos.",
    //         "Easy Management: Simple setup and management of bathroom listings.",
    //         "Hygiene Monitoring: Ensure consistent hygiene with digital logs.",
    //         "Issue Reporting: Identify and address problems before they escalate.",
    //         "Boost Reputation: Keep restrooms spotless, enhance customer satisfaction.",
    //         "Prevent Costly Repairs: Spot issues early with photo verification.",
    //         "Affordable Pricing: All features for just $0.99/month.",
    //         "Peace of Mind: Rest assured your bathrooms meet high standards.",
    //     ]
    // };

    // const productPatterns: { [key: string]: RegExp } = {
    //     "Chifwash": /Chifwash/i
    // };

    // useEffect(() => {
    //     // dispatch(getProductList(token!, 18, undefined, is_app_subscription));
    // }, [dispatch, token, user_id, is_app_subscription])


    // if (!productList || !productList[billingCycle]) {
    //     return null;
    // }

    // const handleUpgrade = (item: any) => {
    //     const data = {
    //         user_id: user_id,
    //         unit_amount: item.unit_amount,
    //         price_id: item.id,
    //         email: email,
    //         name: name,
    //         package_quantity: 1,
    //         interval: item.recurring.interval,
    //         token: token,
    //         is_app_subscription: true,
    //     };
    //     dispatch(addSubscription(data));
    // }

    // const renderProductGridItem = (product: Product) => {
    //     const getProductFeatures = (nickname: string): string[] => {
    //         for (const [key, pattern] of Object.entries(productPatterns)) {
    //             if (pattern.test(nickname)) {
    //                 return featuresMap[key];
    //             }
    //         }
    //         return [];
    //     };
    
    //     const features = getProductFeatures(product.nickname);

    //     return (
    //         <Grid item key={product.id} xs={12} sm={6} md={4}>
    //             <Paper
    //                 elevation={3}
    //                 sx={{
    //                     padding: 3,
    //                     backgroundColor: 'inherit', // White transparent background for subscribed
    //                     border: 'none', // White transparent border for subscribed
    //                     height: '100%', // Set the height to 100% to ensure all cards have the same height
    //                     display: 'flex',
    //                     flexDirection: 'column',
    //                     justifyContent: 'space-between',
    //                     borderRadius: 5,
    //                     boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    //                 }}
    //             >
    //                 <Box sx={{ flex: 1 }}>
    //                     <Typography variant="h5" gutterBottom sx={{color: '#fff', fontWeight: 'bold'}}>
    //                         {product.nickname}
    //                     </Typography>
    //                     <Typography variant="h6" gutterBottom sx={{color: '#fff'}}>
    //                         ${(product.unit_amount / 100).toFixed(2)}
    //                     </Typography>
    //                     <Typography variant="subtitle1" gutterBottom  sx={{color: '#fff'}}>
    //                         {product.recurring.interval === 'year' ? 'per year' : 'per month'}
    //                     </Typography>
    //                 </Box>
    //                     <Button 
    //                         size="small"
    //                         variant="contained"
    //                         sx={{
    //                             mt: 2,
    //                             backgroundColor: 'rgb(30, 28, 120)', // Background color
    //                             color: 'white', // Text color
    //                             '&:hover': {
    //                                 backgroundColor: 'rgba(30, 28, 120, 0.8)', // Slightly lighter color on hover
    //                             },
    //                             borderRadius: 5,
    //                             justifyContent: 'center',
    //                         }}
    //                         onClick={() => handleUpgrade(product)}>
    //                         Add Subscription
    //                     </Button>
    //                     <Box sx={{ mt: 2,color: '#fff'}}>
    //                         <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
    //                             This includes:
    //                         </Typography>
    //                         <ul style={{ paddingLeft: '20px', marginTop: 0 }}>
    //                             {features.map((feature, index) => (
    //                                 <li key={index} style={{ marginBottom: '8px', listStyleType: 'none', display: 'flex', alignItems: 'center' }}>
    //                                     <CheckCircle sx={{ marginRight: '4px', fontSize: '16px' }} />
    //                                     <Typography variant="body2">{feature}</Typography>
    //                                 </li>
    //                             ))}
    //                         </ul>
    //                     </Box>
    //             </Paper>
    //         </Grid>
    //     )
    // }

    return (
        <Box sx={{ padding: 4, backgroundColor: 'rgb(16, 78, 234)', minHeight: '100vh' }}>
            {/* <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 4 }}>
                <ToggleButtonGroup
                    value={billingCycle}
                    exclusive
                    onChange={handleBillingCycleChange}
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        borderRadius: 5,
                        '& .MuiToggleButton-root': {
                            color: '#fff',
                            borderColor: '#fff',
                            '&.Mui-selected': {
                                backgroundColor: 'rgba(30,28,120,1.000)',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                },
                                color: '#fff',
                            },
                            borderRadius: 5,
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            },
                        },
                    }}
                >
                    <ToggleButton value="monthly">Monthly</ToggleButton>
                    <ToggleButton value="yearly">Yearly</ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid container spacing={3} sx={{ width: '100%', maxWidth: '1200px' }}>
                    {productList[billingCycle].map(renderProductGridItem)}
                </Grid>
            </Box> */}
            {/* <FeaturesModal
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
                features={modalFeatures}
            /> */}
        </Box>
    );
};

export default AppSubscription;