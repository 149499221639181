import { Avatar, Button, Card, IconButton, TextField, Theme, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState, useRef } from 'react'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Storefront } from '@mui/icons-material';
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'
import moment from 'moment'
import {
  fetchUser,
  updateProfilePicture,
  updateUserBusinessDiscount,
  connectStripe,
  getStripeOnboardingStatus,
  getRetrieveSubscriptionData,
  getUserBusiness,
  submitStripeRequirements,
  getGenerateAffiliateLink
} from '../../../../store/app/user/actions'
import Slider from '@mui/material/Slider'
import Box from '@mui/material/Box'
import DiscountModal from '../../../component/DiscountModal'
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmationModal from '../../../component/DeleteModal';
import { useNavigate } from 'react-router-dom'
import Toaster from '../../../../theme/components/Toaster';
import FullPageLoader from '../../../../app/utils/fullpageLoader';
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ContentCopy from '@mui/icons-material/ContentCopy';
const useStyle = makeStyles((theme: Theme) => ({
  image: {
    width: '200px',
    height: '200px',
    borderRadius: '50%',
    objectFit: 'cover',
    [theme.breakpoints.down(1024)]: {
      width: '100px',
      height: '100px',
    },
  },
  tab: {
    cursor: 'pointer',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 600,
    borderRadius: '5px',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  activeTab: {
    color: 'black',
    borderBottom: '3px solid #1976d2',
  },
  card: {
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  },
  username: {
    fontSize: '20px',
    fontWeight: 600,
    color: '#333',
    marginTop: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  deleteButton: {
    marginTop: '16px',
    padding: '10px 20px',
    backgroundColor: '#f44336',
    color: 'white',
    borderRadius: '8px',
    fontWeight: 'bold',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#d32f2f',
    },
  },
  saveButton: {
    marginTop: '24px',
    padding: '12px 20px',
    backgroundColor: '#1976d2',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '8px',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
  },
}))

function Profile() {
  const classes = useStyle()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { userData, businessInfo, stripeOnboardingStatus, userRetrieveSubscriptionData, userBusinessStripeData, loading, affiliateLink } = useSelector(
    (state: ApplicationState) => state.user
  )

  const [activeTab, setActiveTab] = useState<string>('profile') // State for active tab

  const [locationObject, setLocationObject] = useState<any>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [additionalData, setAdditionalData] = useState<any>(null);

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const [onboardingStatus, setOnboardingStatus] = useState<boolean>(false);

  const initialSliderValues = useMemo(() => {

    const initialValues: { [key: string]: number } = {};

    const discountData = userData?.user_discounts?.discount_data
      ? JSON.parse(userData.user_discounts.discount_data)
      : null;
    let combinedData;
    if (additionalData) {
      combinedData = Array.from(
        new Map(
          [...(discountData?.default || []), ...(discountData?.additional || []), ...additionalData].map(
            (discount) => [discount.id, discount]
          )
        ).values()
      );
    } else {
      combinedData = Array.from(
        new Map(
          [...(discountData?.default || []), ...(discountData?.additional || [])].map(
            (discount) => [discount.id, discount]
          )
        ).values()
      );
    }

    combinedData.forEach((discount) => {
      const discountType = discount.discount_name.toLowerCase().replace(' ', '_');
      initialValues[discountType] = discount.discount_value || 0;
    });

    return initialValues;
  }, [userData, additionalData]);

  const previousSliderValues = useRef(initialSliderValues);
  const [sliderValues, setSliderValues] = useState<{ [key in string]: number }>(initialSliderValues || {});

  useEffect(() => {
    const data: { location: string | undefined } = {
      location: businessInfo?.location,
    };

    if (data.location) {
      setLocationObject(JSON.parse(data.location));
    }
  }, [businessInfo?.location]);

  useEffect(() => {
    if (userData && !onboardingStatus) {
      if (userData.id !== undefined) {
        dispatch(getStripeOnboardingStatus(userData?.id))
        dispatch(getUserBusiness(userData.id));
        // dispatch(getGenerateAffiliateLink(userData.user_info?.affiliate_code));
        if (stripeOnboardingStatus) {
          dispatch(getRetrieveSubscriptionData(userData.id))
          setOnboardingStatus(true);
          dispatch(getGenerateAffiliateLink(userData.user_info?.affiliate_code));
        }
      }
    }
  }, [stripeOnboardingStatus, dispatch, userData, onboardingStatus, userRetrieveSubscriptionData])

  useEffect(() => {
    if (JSON.stringify(previousSliderValues.current) !== JSON.stringify(initialSliderValues)) {
      setSliderValues(initialSliderValues);
      previousSliderValues.current = initialSliderValues;
    }
  }, [initialSliderValues]);

  const valueText = (value: number) => {
    return `${value}%`
  }

  const handleSliderChange = (
    event: any,
    newValue: number | number[],
    discountType: string
  ) => {
    setSliderValues((prev) => ({
      ...prev,
      [discountType]: newValue as number,
    }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    discountType: string
  ) => {
    const value = event.target.value;
    if (value === '') {
      setSliderValues((prev) => ({
        ...prev,
        [discountType]: 0,
      }));
    } else if (!isNaN(Number(value))) {
      const newValue = Math.min(100, Math.max(0, Number(value)));
      setSliderValues((prev) => ({
        ...prev,
        [discountType]: newValue,
      }));
    }
  };

  const handleUploadProfilePicture = (event: any): void => {
    if (userData.id) {
      const file_list = event.target.files
      const form_data = new FormData()
      form_data.append('user_id', userData.id.toString())
      form_data.append('userImage', file_list[0])
      dispatch(updateProfilePicture(form_data))
    }
  }

  const handleSave = () => {

    if (!stripeOnboardingStatus?.onboard_complete && !stripeOnboardingStatus?.is_need_requirement) {
      Toaster.warning('You need to connect your stripe account first before using the discount. Please go to subscription tab first.');
      return;
    }

    if (stripeOnboardingStatus?.is_need_requirement) {
      Toaster.warning('You need to complete your stripe account first and submit other requirements for stripe before using the discount. Please go to subscription tab.');
      return;
    }

    const settingDiscount = userData.user_discounts?.discount_data
      ? JSON.parse(userData?.user_discounts?.discount_data)
      : { default: [], additional: [] };

    // Combine the additional discounts with the default ones
    let combinedAdditionalDiscount;
    if (additionalData) {
      combinedAdditionalDiscount = [...settingDiscount.additional, ...additionalData];
    } else {
      combinedAdditionalDiscount = settingDiscount.additional;
    }

    // Combine default and additional discounts
    const combinedDiscounts = {
      default: settingDiscount.default || [],
      additional: combinedAdditionalDiscount || [],
    };

    // Update combined discounts with slider values
    const updatedCombinedDiscountsDefault = settingDiscount.default.map((discount: any) => {
      const sliderValue = sliderValues[discount.discount_name.toLowerCase().replace(' ', '_')];
      return {
        ...discount,
        discount_value: sliderValue !== undefined ? sliderValue : discount.discount_value, // Use slider value if available
      };
    });

    const updatedCombinedDiscountsAdditional = combinedDiscounts.additional.map((discount: any) => {
      const sliderValue = sliderValues[discount.discount_name.toLowerCase().replace(' ', '_')];
      return {
        ...discount,
        discount_value: sliderValue !== undefined ? sliderValue : discount.discount_value, // Use slider value if available
      };
    });

    // Combine final discounts
    const finalCombinedDiscounts = {
      default: updatedCombinedDiscountsDefault,
      additional: updatedCombinedDiscountsAdditional,
    };

    // Dispatch the action
    dispatch(updateUserBusinessDiscount(finalCombinedDiscounts));
    dispatch(fetchUser({ user_id: userData.id, mobile_number: userData?.mobile_number, email: userData?.email }))
  };

  const handleAddDiscount = (name: string, value: number | null) => {
    const discountKey = name.toLowerCase().replace(" ", "_");
    const newDiscount = {
      id: new Date().toISOString(), // Unique ID
      user_id: userData?.user_discounts?.user_id,
      discount_name: name,
      discount_value: value || 0,
      is_enabled: true,
      is_removable: true,
    };

    setAdditionalData((prevState: any[]) => {
      // If prevState is not an array, initialize it as an empty array
      return Array.isArray(prevState) ? [...prevState, newDiscount] : [newDiscount];
    });

    setSliderValues((prevState) => ({
      ...prevState,
      [discountKey]: value || 0, // Add the new discount value without overwriting the existing ones
    }));
  };

  const handleDeleteDiscount = () => {
    const settingDiscount = userData.user_discounts?.discount_data
      ? JSON.parse(userData?.user_discounts?.discount_data)
      : { additional: [], default: [] };
    let updateAdditionalDiscount;
    updateAdditionalDiscount = settingDiscount.additional || [];

    const updatedData = updateAdditionalDiscount.filter((item: any) => item.id !== selectedId)

    // Update additionalData state
    setAdditionalData(updatedData);

    const finalDiscounts = {
      default: settingDiscount.default || [],
      additional: updatedData,
    };

    // dispatch to update
    dispatch(updateUserBusinessDiscount(finalDiscounts));
    // Close modal
    handleDeleteCloseModal();
    // dispatch to refetch user
    dispatch(fetchUser({ user_id: userData.id, mobile_number: userData?.mobile_number, email: userData?.email }))
  };

  const handleDisableDiscount = (id: string) => {
    console.log(`Disable : ${id}`)
    setSelectedId(id);

    // Find the discount in the default and additional arrays
    const settingDiscount = userData.user_discounts?.discount_data
      ? JSON.parse(userData?.user_discounts?.discount_data)
      : { default: [], additional: [] };

    const updatedDiscountsDefault = settingDiscount.default.map((discount: any) => {
      if (discount.id === id) {
        return { ...discount, is_enabled: false }; // Disable the discount
      }
      return discount;
    });

    const updatedDiscountsAdditional = settingDiscount.additional.map((discount: any) => {
      if (discount.id === id) {
        return { ...discount, is_enabled: false }; // Disable the discount
      }
      return discount;
    });

    // Combine updated default and additional
    const finalDiscounts = {
      default: updatedDiscountsDefault,
      additional: updatedDiscountsAdditional,
    };

    // console.log(`Let's check the disabled discount : ${JSON.stringify(finalDiscounts, null, 2)}`)
    // Dispatch to update the discounts
    dispatch(updateUserBusinessDiscount(finalDiscounts));
    dispatch(fetchUser({ user_id: userData.id, mobile_number: userData?.mobile_number, email: userData?.email }));
  }

  const handleEnableDiscount = (id: string) => {
    setSelectedId(id);

    // Find the discount in the default and additional arrays
    const settingDiscount = userData.user_discounts?.discount_data
      ? JSON.parse(userData?.user_discounts?.discount_data)
      : { default: [], additional: [] };

    const updatedDiscountsDefault = settingDiscount.default.map((discount: any) => {
      if (discount.id === id) {
        return { ...discount, is_enabled: true }; // Disable the discount
      }
      return discount;
    });

    const updatedDiscountsAdditional = settingDiscount.additional.map((discount: any) => {
      if (discount.id === id) {
        return { ...discount, is_enabled: true }; // Disable the discount
      }
      return discount;
    });

    // Combine updated default and additional
    const finalDiscounts = {
      default: updatedDiscountsDefault,
      additional: updatedDiscountsAdditional,
    };

    // console.log(`Let's check the disabled discount : ${JSON.stringify(finalDiscounts, null, 2)}`)
    // Dispatch to update the discounts
    dispatch(updateUserBusinessDiscount(finalDiscounts));
    dispatch(fetchUser({ user_id: userData.id, mobile_number: userData?.mobile_number, email: userData?.email }));
  }

  const handleDeleteOpenModal = (id: string) => {
    setSelectedId(id);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteCloseModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedId(null);
  };

  const combinedDiscounts = useMemo(() => {
    const settingDiscount = userData.user_discounts?.discount_data
      ? JSON.parse(userData?.user_discounts?.discount_data)
      : { additional: [], default: [] };

    // Combine default and additional discounts
    let combinedData = [...settingDiscount.default, ...settingDiscount.additional];

    // If additionalData is set, combine it with the defaults and the initial additional
    if (additionalData && additionalData.length > 0) {
      combinedData = [...combinedData, ...additionalData];
    }

    // Remove duplicates based on the `id`
    const uniqueData = Array.from(new Map(combinedData.map((item: any) => [item.id, item])).values());

    return uniqueData;
  }, [userData, additionalData]);

  // connect to stripe dispatch
  const connectToStripe = () => {
    const connectData = {
      user_id: userData.id,
      refresh_url: `${process.env.REACT_APP_DOMAIN}/stripe-process`,
      return_url: `${process.env.REACT_APP_DOMAIN}/business-owner/profile`,
    }
    dispatch(connectStripe(connectData))
  }

  const submitStripeRequirement = () => {
    const submitRequirements = {
      user_id: userData.id,
      stripe_account_id: userBusinessStripeData?.stripe_account_id,
      refresh_url: `${process.env.REACT_APP_DOMAIN}/stripe-process`,
      return_url: `${process.env.REACT_APP_DOMAIN}/business-owner/profile`,
    }
    dispatch(submitStripeRequirements(submitRequirements))
  }

  // navigate to the products
  const viewProducts = () => {
    const queryParams = new URLSearchParams({
      user_id: String(userData.id),
      user_type: String(userData.user_type),
      is_app_subscribe: String(userData.is_app_subscription),
      email: String(userData.email),
      name: String(userData.user_info?.first_name)
    });
    navigate(`/add-subscription-mobile?${queryParams.toString()}`)
  }

  const addProduct = () => {

  }

  const handleCopy = async (affiliateLink: string | undefined) => {
    try {
      // Assert clipboard-write as a valid PermissionDescriptor type
      await navigator.permissions.query({ name: "clipboard-write" } as unknown as PermissionDescriptor);
      await navigator.clipboard.writeText(affiliateLink ?? '');
      Toaster.success("Text copied!");
    } catch (error) {
      Toaster.error("Clipboard copy failed:");
    }
  };

  return (
    <div className="w-full h-full bg-gray-50 p-6">
      {/* Tab Navigation */}
      <div className="flex justify-center gap-8 mb-6 border-b border-gray-200 pb-4">
        {['profile', 'discounts', 'subscription'].map((tab) => (
          <button
            key={tab}
            className={`${classes.tab} ${activeTab === tab ? classes.activeTab : ''
              }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>

      {/* Main Content */}
      <div className="flex flex-col lg:flex-row items-start gap-8">
        {/* Profile Picture Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            padding: 3,
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          {userData?.user_info?.image_url ? (
            <Avatar
              src={userData.user_info.image_url}
              sx={{ width: 100, height: 100 }}
              alt="User"
            />
          ) : (
            <Avatar sx={{ width: 100, height: 100 }} />
          )}

          {/* Profile Picture Upload */}
          <Tooltip title="Change Profile Picture">
            <IconButton
              component="label"
              sx={{ marginTop: 2, backgroundColor: "primary.main", color: "white" }}
            >
              <CameraAltIcon />
              <input
                type="file"
                onChange={(event) => handleUploadProfilePicture(event)}
                hidden
                accept="image/*"
              />
            </IconButton>
          </Tooltip>

          {/* Username Section */}
          <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
            <AccountBoxIcon sx={{ marginRight: 1, color: "text.secondary" }} />
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              @{userData?.user_info?.username || "N/A"}
            </Typography>
          </Box>

          {/* Affiliate Link Section */}
          <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
            <Storefront sx={{ marginRight: 1, color: "text.secondary" }} />
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {userData?.user_info?.affiliate_code || "N/A"}
            </Typography>
            {userData?.user_info?.affiliate_agreement ? (
              <Tooltip title="Copy Affiliate Link">
                <IconButton onClick={() => handleCopy(affiliateLink)} sx={{ color: "gray", fontSize: "10px" }}>
                  <ContentCopy />
                </IconButton>
              </Tooltip>
            ) :
              (
                <Tooltip title="Please activate first before you can copy your link to others. Go to affiliate marketing section.">
                  <IconButton
                    sx={{ color: "gray", fontSize: "10px" }}
                  >
                    <ContentCopy />
                  </IconButton>
                </Tooltip>
              )}
          </Box>

          {/* Delete Account */}
          <Tooltip title="Delete Account">
            <IconButton
              onClick={() => console.log("Deleting User")}
              sx={{
                marginTop: 3,
                backgroundColor: "error.main", // Red background for visibility
                color: "red", // Icon color is white
                "&:hover": { color: "white", backgroundColor: "error.dark" }, // Darker red on hover
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Card Section */}
        <div className="flex-1">
          {activeTab === 'profile' && (
            <Card className={`${classes.card} bg-white`}>
              <h2 className="text-xl font-semibold mb-4">Profile</h2>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="font-medium text-gray-700">Name</p>
                  <p className="text-gray-500">
                    {userData &&
                      `${userData.user_info?.first_name} ${userData.user_info?.last_name}`}
                  </p>
                </div>
                <div>
                  <p className="font-medium text-gray-700">Email</p>
                  <p className="text-gray-500">
                    {userData?.email || 'Not Provided'}
                  </p>
                </div>
                <div>
                  <p className="font-medium text-gray-700">Mobile Number</p>
                  <p className="text-gray-500">
                    {userData?.mobile_number || 'Not Provided'}
                  </p>
                </div>
                <div>
                  <p className="font-medium text-gray-700">Member Since</p>
                  <p className="text-gray-500">
                    {userData &&
                      moment(userData.created_at).format('MMMM DD, YYYY')}
                  </p>
                </div>
                <div>
                  <p className="font-medium text-gray-700">Business Name</p>
                  <p className="text-gray-500">{businessInfo?.business_name}</p>
                </div>
                <div>
                  <p className="font-medium text-gray-700">Industry</p>
                  <p className="text-gray-500">
                    {businessInfo?.business_Industry}
                  </p>
                </div>
                <div>
                  <p className="font-medium text-gray-700">Business Location</p>
                  <p className="text-gray-500">
                    {locationObject && `${locationObject.description}`}
                  </p>
                </div>
              </div>
            </Card>
          )}
          {activeTab === 'discounts' && (
            <Card className={`${classes.card} bg-white`}>
              <h2 className="text-xl font-semibold mb-4">Discounts</h2>
              {/* Render sliders for each discount type */}
              {combinedDiscounts && combinedDiscounts?.map((discount: any) => (
                <div key={discount.id} className="mb-6 flex items-center justify-between">
                  <label className="font-medium">{discount.discount_name}</label>
                  <Slider
                    value={sliderValues[discount.discount_name.toLowerCase().replace(' ', '_')] ?? 0}
                    onChange={(e, newValue) => handleSliderChange(e, newValue, discount.discount_name.toLowerCase().replace(' ', '_'))}
                    valueLabelDisplay="auto"
                    valueLabelFormat={valueText}
                    min={0}
                    max={100}
                  />
                  <Box className="flex items-center mt-2">
                    <input
                      type="number"
                      value={sliderValues[discount.discount_name.toLowerCase().replace(' ', '_')]}
                      onChange={(e) => handleInputChange(e, discount.discount_name.toLowerCase().replace(' ', '_'))}
                      className="ml-4 w-16 p-2 border border-gray-300 rounded"
                    />
                  </Box>
                  {!discount.is_enabled && discount.is_removable && (
                    <IconButton onClick={() => handleEnableDiscount(discount.id)} style={{ marginLeft: 8 }}>
                      <VisibilityOffIcon />
                    </IconButton>
                  )
                  }
                  {discount.is_enabled && discount.is_removable && (
                    <IconButton onClick={() => handleDisableDiscount(discount.id)} style={{ marginLeft: 8 }}>
                      <VisibilityIcon />
                    </IconButton>
                  )
                  }
                  {discount.is_removable && (
                    <IconButton onClick={() => handleDeleteOpenModal(discount.id)} style={{ marginLeft: 8 }}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              ))}
              <button
                className={classes.saveButton}
                onClick={() => setIsModalOpen(true)}
              >
                + Add Discount
              </button>
              <div></div>
              <button
                className={classes.saveButton}
                onClick={handleSave}
              >
                Save Changes
              </button>
            </Card>
          )}

          <DiscountModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSubmit={handleAddDiscount}
          />
          <DeleteConfirmationModal
            open={isDeleteModalOpen}
            onClose={handleDeleteCloseModal}
            onConfirm={handleDeleteDiscount}
          />
          {activeTab === 'subscription' && (
            <Card className={`${classes.card} bg-white`}>
              <h2 className="text-xl font-semibold mb-4">Subscription</h2>
              {/* Subscription content here */}
              {!stripeOnboardingStatus?.onboard_complete && !stripeOnboardingStatus?.is_need_requirement ? (
                <button
                  className={classes.saveButton}
                  onClick={() => connectToStripe()}
                >
                  Connect to stripe
                </button>
              ) : !stripeOnboardingStatus.onboard_complete && stripeOnboardingStatus.is_need_requirement ? (
                <button
                  className={classes.saveButton}
                  onClick={() => submitStripeRequirement()}
                >
                  Submit Requirement
                </button>
              ) : (
                <div>
                  {Object.keys(userRetrieveSubscriptionData).length > 0 ? (
                    <>
                      <p className="text-gray-500">
                        Status: Connected
                      </p>
                      {userRetrieveSubscriptionData.subscriptionDataArray.map(
                        (subscription: any) => (
                          <div key={subscription.getSubscriptionData.id} className="mb-4">
                            <p className="font-medium text-gray-500">
                              Subscription: {subscription.getSubscriptionData.items.data[0].plan.nickname}
                            </p>
                          </div>
                        )
                      )
                      }
                      {userRetrieveSubscriptionData.isCanAddSubscription &&
                        <button
                          className={classes.saveButton}
                          onClick={() => addProduct()}
                        >
                          Add Product
                        </button>
                      }
                    </>
                  ) : (
                    <>
                      <p className="text-gray-500">
                        Status: Connected
                      </p>
                      <p className="text-gray-500">
                        Subscription: None
                      </p>
                      <button
                        className={classes.saveButton}
                        onClick={() => viewProducts()}
                      >
                        View Product
                      </button>
                    </>
                  )}
                </div>
              )}
            </Card>
          )}
          {loading.infoData && (
            <FullPageLoader />
          )}
        </div>
      </div>
    </div>
  )
}

export default Profile
