export enum SuperAdminTypes {
    GET_SUPER_ADMIN = '@@super_admin/GET_SUPER_ADMIN',
    SET_SUPER_ADMIN = '@@super_admin/SET_SUPER_ADMIN',
    SAVE_SETTINGS = '@@super_admin/SAVE_SETTINGS',
    UPDATE_SETTINGS = '@@super_admin/UPDATE_SETTINGS',
    DELETE_SETTINGS = '@@super_admin/DELETE_SETTINGS',
    GET_ALL_PAYOUT_REQUEST = '@@super_admin/GET_ALL_PAYOUT_REQUEST',
    SET_ALL_PAYOUT_REQUEST = '@@super_admin/SET_ALL_PAYOUT_REQUEST',
    PAYOUT_ACCEPT_DECLINE_REQUEST='@@super_admin/PAYOUT_ACCEPT_DECLINE_REQUEST',
}

export interface SuperAdminState {
    readonly superAdminSettingsData: any
    readonly payoutRequestData: any
}