
import {
  FormControlLabel,
  InputLabel,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import UIConstants from '../../../theme/constants/ui-constants'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import background from '../../../assets/images/background.png'
import logo from './../../../assets/images/logo.svg'
import mobileLogo from './../../../assets/images/logo_mobile.svg'
import { format, isValidPhoneNumber, parse } from 'libphonenumber-js'
import { PhoneInput } from '../../../theme/components/input/PhoneField'
import { Toggle } from '../../../theme/components/input/Toggle'
import { CustomButton } from '../../../theme/components/buttons/Button'
import SocialMediaLoginComponents from '../../pages/login/SocialMediaLoginComponents'
import { ApplicationState } from '../../../store'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { setUserData } from '../../../store/app/user/actions'
import { setAuthenticationData } from '../../../store/app/actions'
import { useEffect, useState } from 'react'
import Verification from './Verification'

interface IFormInput {
  phoneNumber: string
  country: string
  isKeepLogin: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: UIConstants.rootBgColor,
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'contain',
    backgroundPosition: 'center top',
    minHeight: `100vh`,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      backgroundSize: 'cover',
    },
  },
  logodiv: {
    minHeight: '9rem',
    position: 'fixed',
    top: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: '15%',
      position: 'unset',
      top: 'unset',
      width: 'auto',
    },
  },
  logo: {
    height: '40px',
    [theme.breakpoints.up('sm')]: {
      height: '70px',
      marginLeft: '15%',
    },
  },
  signupBox: {
    background: UIConstants.rootBgColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: `0 ${theme.spacing(3)}`,
    position: 'relative',
    top: '4rem',
    height: 'calc(100vh - 9rem)',
    [theme.breakpoints.up('sm')]: {
      position: 'unset',
      maxWidth: '400px',
      height: '100%',
      margin: 'auto',
      top: 'unset',
      padding: `${theme.spacing(3)}`,
    },
  },
  heading: {
    margin: `${theme.spacing(1)} ${theme.spacing(4)}`,
    '& .mainHeading': {
      fontWeight: 600,
      fontSize: '18px',
      fontFamily: UIConstants.appFont,
      color: UIConstants.primaryBgColor,
    },
    '& .subHeading': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '27px',
      fontFamily: UIConstants.appFont,
      color: UIConstants.primaryBgColor,
    },
  },
  signUpInputBox: {
    minWidth: '250px',
    margin: `${theme.spacing(4)} 0`,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: '350px',
    },
  },
  formControl: {
    margin: `${theme.spacing(3)} ${theme.spacing(1)}`,
    display: 'flex',
    // flexDirection: 'column-reverse',
  },
  becomeAHost: {
    color: UIConstants.primaryBgColor,
    margin: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  termsAndCondition: {
    color: UIConstants.primaryBgColor,
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      opacity: '0.5',
    },
  },
  labelOtherSignup: {
    color: UIConstants.primaryBgColor,
    fontSize: '14px',
    opacity: '0.5',
  },
  link: {
    textDecoration: 'none',
    color: UIConstants.primaryBgColor,
    fontSize: '14px',
    fontWeight: 400,
    opacity: '.5',
    '&:hover': {
      opacity: '1',
    },
  },
}))

function LoginPage() {
  let navigate = useNavigate()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const verified = localStorage.getItem('verified') && localStorage.getItem('verified')

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { userData } = useSelector((state: ApplicationState) => state.user)
  const { authData } = useSelector((state: ApplicationState) => state.app)
  const appLogo = matches ? logo : mobileLogo
  const countries = process.env.REACT_APP_SUPPORTED_COUNTRIES?.split(',')
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    mode: 'all',
  })

  const classes = useStyles()

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    // console.log(data);
    // setLogin({ phoneNumber: parse(data.phoneNumber).phone as string, keepLoggedIn: data.isKeepLogin });
    const user = _.cloneDeep(userData)
    const auth = _.cloneDeep(authData)
    const { country, phone } = parse(data.phoneNumber)
    user.mobile_number = format(phone, country, 'E.164')
    auth.keepLoggedIn = data.isKeepLogin
    auth.country_code = data.country
    dispatch(setUserData(user))
    dispatch(setAuthenticationData(auth))
    navigate('verify-otp')
  }

  const logoClick = () => {
    navigate(`/landing`)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.root}>
        <div className={classes.logodiv}>
          {' '}
          <img
            src={appLogo}
            alt={t('appName')}
            className={classes.logo}
            onClick={() => logoClick()}
          />
        </div>
        <Paper elevation={matches ? 3 : 0} className={classes.signupBox}>
          {Boolean(verified) ? (
            <>
              <div className={classes.heading}>
                <Typography className="mainHeading">
                  {t('loginPage.mainHeading')}
                </Typography>
                <Typography className="subHeading">
                  <span style={{ opacity: 0.5 }}>
                    {t('loginPage.description')}&nbsp;
                  </span>
                  <Link to="/signup" className={classes.link}>
                    {t('loginPage.signUp')}
                  </Link>
                </Typography>
              </div>
              <div className={classes.signUpInputBox}>
                <div className={classes.formControl}>
                  <PhoneInput
                    onlyCountries={countries}
                    fullWidth
                    label={t('signUp.phoneNumberLabel')}
                    variant="outlined"
                    size="small"
                    defaultCountry={'us'}
                    control={control}
                    name={'phoneNumber'}
                    error={errors?.phoneNumber}
                    helperText={errors?.phoneNumber?.message}
                  ></PhoneInput>
                </div>
                <div className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="isKeepLogin"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <Toggle
                            checked={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    }
                    label={t('loginPage.keepLogIn') as string}
                    labelPlacement="start"
                    className={classes.becomeAHost}
                  />
                </div>
                <div className={classes.formControl}>
                  <CustomButton
                    type="submit"
                    variant="contained"
                    disabled={!isValid}
                    fullWidth
                  >
                    {t('loginPage.login')}
                  </CustomButton>
                </div>
                <div
                  className={classes.formControl}
                  style={{ justifyContent: 'center' }}
                >
                  <InputLabel className={classes.labelOtherSignup}>
                    {t('signUp.continueWithSocialMedia')}
                  </InputLabel>
                </div>
                <SocialMediaLoginComponents getLoginData={() => {}} />
              </div>
            </>
          ) : (
            <Verification />
          )}
        </Paper>
      </div>
    </form>
  )
}

export default LoginPage
