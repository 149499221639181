import axios, { Method, AxiosRequestConfig } from 'axios';
import { store } from '../../configureStore';
import { logout } from '../../store/app/user/actions';
import toaster from '../../theme/components/Toaster';
import i18n from 'i18next';

export interface ApiResponse {
	headers?: any;
	data?: any;
	error: string | boolean | any;
}

export async function callApi(
	method: Method,
	path: string,
	data?: any,
	headers?: any,
	params?: any,
	token?: string,
	includeApiKey?: boolean // new parameter: include x-api-key header when true
): Promise<ApiResponse> {
	const config: AxiosRequestConfig = getRequestConfig(
		method,
		path,
		data,
		headers,
		params,
		token,
		includeApiKey
	);
	const response: ApiResponse = {
		data: undefined,
		headers: undefined,
		error: false,
	};

	try {
		const result = await axios(config);
		response.data = result.data;
		response.headers = result.headers;
	} catch (error: any) {
		if (error?.response?.data?.error) {
			response.error = error.response.data.error; // Capture specific error message
		} else if (error?.response?.data?.msg) {
			response.error = error.response.data.msg;
		} else if (error?.response?.status === 409) {
			response.error = 'Conflict: The resource already exists'; // Handle 409 Conflict
		} else if (error?.response?.status === 401) {
			response.error = 'Unauthorized: Please check your credentials';
		} else if (error?.response?.status === 400) {
			response.error = 'Bad Request: Invalid input data';
		} else {
			response.error = 'An unexpected error occurred. Please try again.';
		}
	}
	return response;
}

function getRequestConfig(
	method: Method,
	path: string,
	data?: any,
	headers?: any,
	params?: any,
	token?: string,
	includeApiKey?: boolean // new parameter here as well
): AxiosRequestConfig {
	// Define the default headers with accept and Authorization.
	const defaultHeaders: Record<string, string> = {
		accept: 'application/json',
	};

	// Conditionally add Authorization header only if the API key is not used.
	if (!includeApiKey) {
		defaultHeaders['Authorization'] = token
		  ? `Bearer ${token}`
		  : `Bearer ${store.getState().app.authData.token}`;
	}
	
	// Conditionally add x-api-key if includeApiKey is true
	if (includeApiKey) {
		const apiKey = process.env.REACT_APP_X_API_KEY;
		console.log('API KEY from env:', apiKey); // Debug log
		if (apiKey) {
		  defaultHeaders['x-api-key'] = apiKey;
		}
	  }

	// Merge with any customer provided headers; provided headers override defaults.
	const finalHeaders = { ...defaultHeaders, ...headers };

	console.log("Axios Config Headers:", finalHeaders);

	// Merge the data and params objects into one.
	// In case of conflict, properties in params will override those in data.
	const merged = {
		...((typeof data === 'object' && data) || {}),
		...((typeof params === 'object' && params) || {}),
	};

	const config: AxiosRequestConfig = {
		method,
		url: `${process.env.REACT_APP_API_ENDPOINT}/${path}`,
		headers: finalHeaders,
		withCredentials: true,
	};

	// For GET requests, use the merged object as query parameters.
	// For other methods (like DELETE, POST, PUT), send the merged object as both query parameters and body.
	if (method.toLowerCase() === 'get') {
		config.params = merged;
	} else {
		config.params = merged;
		config.data = merged;
	}

	return config;
}

const UNAUTHORIZED = 401;
axios.interceptors.response.use(
	(response) => response,
	(error) => {
		const { status } = error.response;
		if (status === UNAUTHORIZED && store.getState().app.authData.isAuthenticated) {
			toaster.error(i18n.t('errorMsg.sessionTimedOut'));
			store.dispatch(logout());
			window.localStorage.clear();
		} else {
			return Promise.reject(error);
		}
	}
);