import React from 'react';
import {
  Paper,
  Typography,
  Box,
  Button,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { CustomButton } from '../../../theme/components/buttons/Button';
import { CartItem } from './types';

interface CartProps {
  cart: CartItem[];
  updateQuantity: (priceId: string, newQuantity: number) => void;
  removeFromCart: (priceId: string) => void;
  cartTotal: number;
  handleCheckout: () => void;
  theme: any;
}

const Cart: React.FC<CartProps> = ({
  cart,
  updateQuantity,
  removeFromCart,
  cartTotal,
  handleCheckout,
  theme,
}) => (
  <Paper sx={{ p: 3, mt: 2, boxShadow: 3, borderRadius: '8px', maxHeight: '400px', overflowY: 'auto' }}>
    <Typography variant="h6" gutterBottom>Your Cart</Typography>
    {cart.length === 0 ? (
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
        Cart is empty
      </Typography>
    ) : (
      <>
        {cart.map(item => (
          <Box
            key={item.priceId}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              mb: 2,
              p: 1,
              borderRadius: '4px',
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1">
                {item.product.nickname}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                ${(
                  (item.product.billing_scheme === 'tiered'
                    ? (item.product.tiers?.[0]?.unit_amount || 0)
                    : (item.product.unit_amount || 0))
                  * item.quantity / 100
                ).toFixed(2)}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Button
                size="small"
                variant="outlined"
                onClick={() => updateQuantity(item.priceId, item.quantity - 1)}
              >-</Button>
              <Typography>{item.quantity}</Typography>
              <Button
                size="small"
                variant="outlined"
                onClick={() => updateQuantity(item.priceId, item.quantity + 1)}
              >+</Button>
              <IconButton
                size="small"
                onClick={() => removeFromCart(item.priceId)}
                color="error"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Typography variant="h6">Total:</Typography>
          <Typography variant="h6">${cartTotal.toFixed(2)}</Typography>
        </Box>
        <CustomButton
          variant="contained"
          fullWidth
          color="primary"
          onClick={handleCheckout}
          sx={{
            py: 1.5,
            borderRadius: '8px',
            fontWeight: 700,
            fontSize: '1rem',
            textTransform: 'none',
          }}
        >
          Checkout Now
        </CustomButton>
      </>
    )}
  </Paper>
);

export default Cart;