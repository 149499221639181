import _ from 'lodash'
import { Reducer } from 'redux'
import { AppActionTypes } from '../types'
import { SuperAdminTypes, SuperAdminState } from './types'

// Type-safe initialState!
export const initialState: SuperAdminState = {
    superAdminSettingsData:{},
    payoutRequestData:{},
}

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<SuperAdminState> = (
  state = initialState,
  action
) => {
  let newState = { ...state }
  switch (action.type) {
    case AppActionTypes.INIT: {
      return initialState
    }
    case SuperAdminTypes.SET_SUPER_ADMIN: {
        return {
            ...newState,
            superAdminSettingsData: action.payload
        }
    }
    case SuperAdminTypes.SET_ALL_PAYOUT_REQUEST: {
        return {
            ...newState,
            payoutRequestData: action.payload
        }
    }
    default: {
      return state
    }
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as superAdminReducer }
