import React, { useCallback } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
  Button,
  Collapse,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomButton } from '../../../theme/components/buttons/Button';
import { Product } from '../../../store/app/user/types';

interface ProductCardProps {
  product: Product;
  type: string;
  subscriptionKey: string;
  expandedTiers: { [key: string]: boolean };
  toggleTiers: (priceId: string) => void;
  addToCart: (product: Product, subscriptionKey: string) => void;
  calculateOriginalPrice: (price: number) => string;
  disabled: boolean;
}

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  type,
  subscriptionKey,
  expandedTiers,
  toggleTiers,
  addToCart,
  calculateOriginalPrice,
  disabled,
}) => {
  const theme = useTheme();

  const getPrice = useCallback(() => {
    if (product.billing_scheme === 'tiered') {
      return product.tiers?.[0]?.unit_amount
        ? product.tiers[0].unit_amount / 100
        : 0;
    }
    return product.unit_amount ? product.unit_amount / 100 : 0;
  }, [product]);

  return (
    <Card variant="outlined" sx={{ height: '100%' }}>
      <CardContent
        sx={{
          p: 3,
          position: 'relative',
          minHeight: '320px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {type === 'yearly' && (
          <Box
            sx={{
              position: 'absolute',
              top: 20,
              right: -28,
              backgroundColor: theme.palette.success.main,
              color: theme.palette.success.contrastText,
              transform: 'rotate(45deg)',
              px: 4,
              py: 0.5,
              fontSize: '0.75rem',
              fontWeight: 'bold',
              zIndex: 1,
            }}
          >
            16% OFF
          </Box>
        )}
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            {product.nickname}
          </Typography>
          <Box sx={{ mb: 2 }}>
            {type === 'yearly' ? (
              <>
                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                  <Typography
                    variant="h3"
                    sx={{ fontWeight: 800, color: theme.palette.primary.main }}
                  >
                    ${getPrice()}
                  </Typography>
                  <Typography
                    component="s"
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: '1.5rem',
                    }}
                  >
                    ${calculateOriginalPrice(getPrice())}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.success.main, mt: 0.5 }}
                >
                  Save additional 16% annually
                </Typography>
              </>
            ) : (
              <Typography variant="h3" sx={{ fontWeight: 800 }}>
                ${getPrice()}
                <Typography
                  component="span"
                  variant="body1"
                  sx={{ color: theme.palette.text.secondary, ml: 0.5 }}
                >
                  /mo
                </Typography>
              </Typography>
            )}
          </Box>
        </Box>

        {product.billing_scheme === 'tiered' && product.tiers && (
          <Box sx={{ mt: 2 }}>
            <Button
              size="small"
              endIcon={<ExpandMoreIcon />}
              onClick={() => toggleTiers(product.id)}
              sx={{ color: theme.palette.primary.main }}
            >
              {expandedTiers[product.id] ? 'Hide' : 'View'} Pricing Tiers
            </Button>
            <Collapse in={expandedTiers[product.id]} timeout={0}>
              <Box
                sx={{
                  mt: 1,
                  p: 2,
                  backgroundColor: theme.palette.background.default,
                  borderRadius: '8px',
                }}
              >
                {product.tiers.map((tier, index) => {
                  const lowerBound =
                    tier.lower_bound ??
                    (index > 0 ? (product.tiers?.[index - 1].up_to || 0) + 1 : 0);
                  const upperBound = tier.up_to || '∞';
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 1,
                        '&:not(:last-child)': {
                          borderBottom: `1px solid ${theme.palette.divider}`,
                          pb: 1,
                        },
                      }}
                    >
                      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                        {tier.up_to ? `${lowerBound}-${upperBound}` : `> ${lowerBound}`} units
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        ${tier.unit_amount ? tier.unit_amount / 100 : 'N/A'}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Collapse>
          </Box>
        )}

        <CardActions sx={{ p: 0, mt: 3 }}>
          <CustomButton
            variant="contained"
            fullWidth
            onClick={() => addToCart(product, subscriptionKey)}
            disabled={disabled}
            sx={{
              py: 1.5,
              borderRadius: '8px',
              fontWeight: 700,
              fontSize: '1rem',
              textTransform: 'none',
            }}
          >
            Add to Cart
          </CustomButton>
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default ProductCard;