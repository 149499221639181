import { action } from 'typesafe-actions'
import {
  SuperAdminTypes,
} from './types'

export const saveSettings = (key: string, value: string) =>
    action(SuperAdminTypes.SAVE_SETTINGS, {key, value})

export const updateSettings = (key: string, value: string) =>
    action(SuperAdminTypes.UPDATE_SETTINGS, {key, value})

export const getSettings = () =>
    action(SuperAdminTypes.GET_SUPER_ADMIN)

export const setSettings = (data: any) =>
    action(SuperAdminTypes.SET_SUPER_ADMIN, data)

export const deleteSettings = (id: number) =>
    action(SuperAdminTypes.DELETE_SETTINGS, {id})

export const getAllPayoutRequest = (page: number, limit: number) =>
    action(SuperAdminTypes.GET_ALL_PAYOUT_REQUEST, {page, limit})

export const setAllPayoutRequest = (data: any) =>
    action(SuperAdminTypes.SET_ALL_PAYOUT_REQUEST, data)

export const payoutAcceptDeclineRequest = (status: string, id: number) =>
    action(SuperAdminTypes.PAYOUT_ACCEPT_DECLINE_REQUEST, {status, id})
