import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { AppState } from './app/types';
import { appReducer } from './app/reducer';
import { UserState } from './app/user/types';
import { userReducer } from './app/user/reducer';
import userSagas from './app/user/sagas';
import { ListingState, ListingsState } from './app/listing/types';
import { listingReducer } from './app/listing/reducer';
import listingSagas from './app/listing/sagas';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage';
import { ReviewState } from './app/review/types';
import { reviewReducer } from './app/review/reducer';
import reviewSagas from './app/review/sagas';
import { BusinessDashboardState } from './app/business_dashboard/types';
import { businessDashboardReducer } from './app/business_dashboard/reducer';
import businessDashboardSagas from './app/business_dashboard/sagas';
import { CommunityState } from './app/community/types';
import communitySagas from './app/community/sagas';
import { businesCommunityReducer } from './app/community/reducer';
import { SuperAdminState } from './app/super_admin/types';
import { superAdminReducer } from './app/super_admin/reducer';
import superAdminSagas from './app/super_admin/saga';
// The top-level state object
export interface ApplicationState {
  app: AppState;
  user: UserState;
  listing: ListingState;
  listings: ListingsState;
  review: ReviewState;
  router: RouterState;
  businessDashboard: BusinessDashboardState;
  businessCommunity: CommunityState;
  tsekmoCommunity: CommunityState;
  superAdmin: SuperAdminState;
}

const persistConfig = {
  key: 'root',
  storage,
  whiteList: ['app']
};

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    app: persistReducer(persistConfig, appReducer),
    listing: listingReducer,
    listings: listingReducer,
    user: userReducer,
    review: reviewReducer,
    businessDashboard: businessDashboardReducer,
    businessCommunity: businesCommunityReducer,
    tsekmoCommunity: businesCommunityReducer,
    superAdmin: superAdminReducer,
  });

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
export function* rootSaga() {
  yield all([
    fork(userSagas),
    fork(listingSagas),
    fork(reviewSagas),
    fork(businessDashboardSagas),
    fork(communitySagas),
    fork(superAdminSagas)
  ]);
}
