import {
  InputLabel,
  MenuItem,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { SelectInput } from '../../../theme/components/input/Select'
import UIConstants from '../../../theme/constants/ui-constants'
import { PhoneInput } from '../../../theme/components/input/PhoneField'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { CustomButton } from '../../../theme/components/buttons/Button'
import SocialMediaComponents from '../../components/signup/SocialMediaComponents'
import { useMemo } from 'react'
import background from '../../../assets/images/background.png'
import logo from '../../../assets/images/logo.svg'
import mobileLogo from '../../../assets/images/logo_mobile.svg'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { parse, format } from 'libphonenumber-js'
import {
  CreateActionModel,
  UserData,
  UserType,
} from '../../../store/app/user/types'
import { useState } from 'react'
import TermsAndCondtions from '../../components/signup/TermsAndCondtions'
import { AuthData } from '../../../store/app/types'
import Verification from '../login/Verification'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: UIConstants.rootBgColor,
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'contain',
    backgroundPosition: 'center top',
    minHeight: `100vh`,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      backgroundSize: 'cover',
    },
  },
  logodiv: {
    minHeight: '9rem',
    position: 'fixed',
    top: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: '15%',
      position: 'unset',
      top: 'unset',
      width: 'auto',
    },
  },
  logo: {
    height: '40px',
    [theme.breakpoints.up('sm')]: {
      height: '70px',
      marginLeft: '15%',
    },
  },
  signupBox: {
    background: UIConstants.rootBgColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: `0 ${theme.spacing(3)}`,
    position: 'relative',
    top: '4rem',
    height: 'calc(100vh - 9rem)',
    [theme.breakpoints.up('sm')]: {
      position: 'unset',
      maxWidth: '400px',
      height: '100%',
      margin: 'auto',
      top: 'unset',
      padding: `${theme.spacing(3)}`,
    },
  },
  heading: {
    margin: `${theme.spacing(1)} ${theme.spacing(4)}`,
    '& .mainHeading': {
      fontWeight: 600,
      fontSize: '18px',
      fontFamily: UIConstants.appFont,
      color: UIConstants.primaryBgColor,
    },
    '& .subHeading': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '27px',
      fontFamily: UIConstants.appFont,
      color: UIConstants.primaryBgColor,
    },
  },
  signUpInputBox: {
    minWidth: '250px',
    margin: `${theme.spacing(4)} 0`,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: '350px',
    },
  },
  formControl: {
    margin: `${theme.spacing(3)} ${theme.spacing(1)}`,
    display: 'flex',
    // flexDirection: 'column-reverse',
  },
  becomeAHost: {
    color: UIConstants.primaryBgColor,
    margin: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  termsAndCondition: {
    color: UIConstants.primaryBgColor,
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      opacity: '0.5',
    },
    '& .MuiFormControlLabel-label:hover': {
      fontSize: '14px',
      opacity: '1',
    },
  },
  labelOtherSignup: {
    color: UIConstants.primaryBgColor,
    fontSize: '14px',
    opacity: '0.5',
  },
  link: {
    textDecoration: 'none',
    color: UIConstants.primaryBgColor,
    fontSize: '14px',
    fontWeight: 400,
    opacity: '.5',
    '&:hover': {
      opacity: '1',
    },
  },
  privacyPolicyText: {
    color: UIConstants.primaryBgColor,
  },
}))

interface IFormInput {
  phoneNumber: string
  country: string
  isHost: boolean
  isAcceptTerms: boolean
}

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

function isTimestampOlderThan24Hours(timestamp: any) {
  const now = new Date()
  const timestampDate = new Date(timestamp * 1000)

  const timeDiffMs = now.getTime() - timestampDate.getTime()
  const timeDiffHours = timeDiffMs / (1000 * 60 * 60)

  return timeDiffHours >= 24
}

interface SignUpProps {
  setUser: (user: UserData) => void
  setValidUser: (isValidUser: boolean) => void
  createUser: (model: CreateActionModel) => void
  userData: UserData
  setAuthData: (model: AuthData) => void
  authData: AuthData
}

export default function SignUp({
  setUser: saveUser,
  setValidUser,
  createUser,
  userData,
  authData,
  setAuthData,
}: SignUpProps) {
  let navigate = useNavigate()
  let query = useQuery()
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const appLogo = matches ? logo : mobileLogo
  const { t } = useTranslation()
  const countries = process.env.REACT_APP_SUPPORTED_COUNTRIES?.split(',')
  const verified =
    localStorage.getItem('verified') && localStorage.getItem('verified')
  const [isTermsOpen, setTermsOpen] = useState<boolean>(false)
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<IFormInput>({
    mode: 'all',
  })
  const watchCountry = watch('country', 'us')
  const watchAllFields = watch()
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const { country, phone } = parse(data.phoneNumber)
    
    const affiliateCode = query.get('affiliate_code')
    localStorage.setItem('affiliate_code', affiliateCode ?? '')
    
    //TODO :: Remove comment as we are not going to save here but just check for the phone number exists
    saveUser({
      country_code: data.country,
      user_type: isBusinessHost
        ? UserType.BUSINESS_OWNER_WITHOUT_SUBSCRIPTION
        : UserType.CUSTOMER_WITHOUT_SUBSCRIPTION,
      mobile_number: format(phone, country, 'E.164'),
      package_subscribed: localStorage.getItem('tsekmo-subscription') ?? '',
    })
    setValidUser(true)
    navigate('verify-otp')
  }
  const logoClick = () => {
    navigate(`/landing`)
  }

  //let's set this to true as always because this PWA is for Entity/Business only
  const isBusiness = () => {
    // if (query.get('isHost')) {
    //   return true
    // }
    // return false
    return true;
  }

  const isRegistered = () => {
    if (
      query.get('isHost') &&
      query.get('order_id') &&
      query.get('session_params') &&
      !isTimestampOlderThan24Hours(query.get('timestamp')) &&
      query.get('from')
    ) {
      const fromParam = query.get('from')
      if (fromParam !== null) {
        localStorage.setItem('tsekmo-subscription', fromParam)
      }
      return true
    } else if (query.get('isHost')) {
      console.log('else if')
      return false
    } else {
      console.log('else')
      return true
    }
  }

  const [isRegisteredHost, setRegisteredHost] = useState<boolean>(isRegistered)

  const [isBusinessHost, setBusinessHost] = useState<boolean>(isBusiness)

  const [showWarning, setShowWarning] = useState<boolean>(false)
  // const clickHost = () => {
  //   setBusinessHost(isBusinessHost ? false : true)
  //   const data = getValues()
  //   setShowWarning(watchAllFields.isHost ? true : false)
  // }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <div className={classes.logodiv}>
            {' '}
            <img
              src={appLogo}
              alt={t('appName')}
              className={classes.logo}
              onClick={() => logoClick()}
            />
          </div>
          {isRegisteredHost ? (
            <Paper elevation={matches ? 3 : 0} className={classes.signupBox}>
              {verified ? (
                <>
                  <div className={classes.heading}>
                    <Typography className="mainHeading">
                      {t('signUp.mainHeading')}
                    </Typography>
                    <Typography className="subHeading">
                      <span style={{ opacity: 0.5 }}>
                        {t('signUp.description')}&nbsp;
                      </span>
                      <Link to="/login" className={classes.link}>
                        {t('signUp.signIn')}
                      </Link>
                    </Typography>
                  </div>
                  <div className={classes.signUpInputBox}>
                    <div className={classes.formControl}>
                      <Controller
                        name="country"
                        control={control}
                        defaultValue="us"
                        render={({ field }) => (
                          <SelectInput
                            {...field}
                            label={t('signUp.countryLabel')}
                            size="small"
                            labelId="signup-country-select"
                          >
                            {countries?.map(
                              (country: string, index: number) => (
                                <MenuItem
                                  value={country}
                                  key={country + index}
                                  disabled={
                                    !(
                                      country === 'us' ||
                                      country === 'in' ||
                                      country === 'ph'
                                    )
                                  }
                                >
                                  {t(`signUp.${country}`)}
                                </MenuItem>
                              )
                            )}
                          </SelectInput>
                        )}
                      />
                    </div>
                    <div className={classes.formControl}>
                      <PhoneInput
                        name={'phoneNumber'}
                        onlyCountries={countries}
                        disableAreaCodes
                        fullWidth
                        label={t('signUp.phoneNumberLabel')}
                        variant="outlined"
                        size="small"
                        defaultCountry={watchCountry}
                        control={control}
                        error={errors?.phoneNumber}
                        helperText={errors?.phoneNumber?.message}
                      ></PhoneInput>
                    </div>
                    <div
                      style={{
                        flexDirection: 'column',
                      }}
                      className={classes.formControl}
                    >
                      {/* <FormControlLabel
                        control={
                          <Controller
                            name="isHost"
                            control={control}
                            defaultValue={isBusinessHost}
                            render={({ field }) => (
                              <Toggle
                                {...field}
                                checked={isBusinessHost}
                                {...register('isHost')}
                                onChange={clickHost}
                                // disabled={isBusinessHost}
                                // disabled={isBusinessHost}
                              />
                            )}
                          />
                        }
                        // disabled={isBusinessHost}
                        label={t('signUp.becomeAHostLabel') as string}
                        labelPlacement="start"
                        className={classes.becomeAHost}
                      /> */}
                      <div
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <Link
                          className={classes.privacyPolicyText}
                          to={'/privacy-policy'}
                          target="_blank"
                        >
                          Privacy Policy
                        </Link>
                      </div>
                    </div>
                    <div className={classes.formControl}>
                      <CustomButton
                        type="submit"
                        variant="contained"
                        disabled={!isValid}
                        fullWidth
                      >
                        {t('signUp.continue')}
                      </CustomButton>
                    </div>
                    {/* <div id="recaptcha-container" style={{ display: "flex", justifyContent: "center" }}></div> */}
                    <div
                      className={classes.formControl}
                      style={{ justifyContent: 'center' }}
                    >
                      <InputLabel className={classes.labelOtherSignup}>
                        {t('signUp.continueWithSocialMedia')}
                      </InputLabel>
                    </div>
                    <SocialMediaComponents
                      createUser={createUser}
                      // isAcceptTerms={watchAllFields.isAcceptTerms}
                      isAcceptTerms={true}
                      isHost={isBusinessHost}
                      country={watchAllFields.country}
                      setUser={saveUser}
                      authData={authData}
                      setAuthData={setAuthData}
                    />
                  </div>
                  <TermsAndCondtions
                    open={isTermsOpen}
                    handleClose={() => setTermsOpen(false)}
                  />
                  {showWarning ? (
                    <>{'You have to add more details to become a host'}</>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Verification />
              )}
            </Paper>
          ) : (
            <Paper
              style={{ maxWidth: '700px' }}
              elevation={matches ? 3 : 0}
              className={classes.signupBox}
            >
              {'Seems the link got expired..'}
              <div>
                {'Please contact support@tsekmo.com for further assistance.'}
              </div>
              <div>
                If you are a new user, please click on{' '}
                <a href="https://www.tsekmo.com">Tsekmo</a> to learn more and
                subscribe.
              </div>
            </Paper>
          )}
        </div>
      </form>
    </>
  )
}
