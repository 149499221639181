
import { Button, ButtonProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import UIConstants from '../../constants/ui-constants';

export const CustomButton = withStyles((theme: Theme) => ({
    root: (props: ButtonProps) => ({
        backgroundColor: !props.color || props.color === "primary" ? UIConstants.primaryBgColor : UIConstants.rootBgColor,
        color: !props.color || props.color === "primary" ? UIConstants.rootBgColor : UIConstants.primaryBgColor,
        fontFamily: UIConstants.appFont,
        // fontSize: "1rem",
        fontWeight: 400,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        // padding: '9px 22px',
        // height: 'fit-content',
        borderRadius: UIConstants.appBorderRadius,
        // lineHeight: '27px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: !props.color || props.color === "primary" ? UIConstants.primaryBgColor : UIConstants.rootBgColor,
            opacity: '0.8'
        }
    })
}))(Button);
