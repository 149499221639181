import React, { useCallback, useMemo } from 'react';
import {
  Modal,
  Typography,
  Paper,
  Grid,
  Box,
  Collapse,
  useTheme,
  Badge,
  IconButton,
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useDispatch } from 'react-redux';
import { createSubscriptionTypes } from '../../../store/app/user/actions';
import { Product } from '../../../store/app/user/types';
import toaster from '../../../theme/components/Toaster';
import ProductCard from './ProductCard';
import Cart from './Cart';
import { SubscriptionModalProps, CartItem } from './types';

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({
  open,
  onClose,
  products,
  registrationData,
  userData,
  userInfoData,
  businessInfoData,
  businessData,
  affiliateData,
  bathroomData,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [expandedTiers, setExpandedTiers] = React.useState<{ [key: string]: boolean }>({});
  const [cart, setCart] = React.useState<CartItem[]>([]);
  const [showCart, setShowCart] = React.useState(false);

  const toggleCart = useCallback(() => setShowCart(prev => !prev), []);

  const addToCart = useCallback((product: Product, subscriptionKey: string) => {
    const newInterval = (product.recurring?.interval as 'month' | 'year') || 'month';
    if (cart.length > 0 && cart[0].interval !== newInterval) {
      alert('Cannot mix monthly and yearly subscriptions in one order');
      return;
    }

    let scaleType: 'single' | 'group' | null = null;
    if (subscriptionKey === 'app') {
      const lookupKey = product.lookup_key.toLowerCase();
      if (lookupKey.includes('single')) {
        scaleType = 'single';
      } else if (lookupKey.includes('group')) {
        scaleType = 'group';
      }
    }

    setCart(prev => {
      const existing = prev.find(
        item => item.priceId === product.id && item.interval === newInterval
      );
      if (existing) {
        return prev.map(item =>
          item.priceId === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
      return [
        ...prev,
        {
          priceId: product.id,
          quantity: 1,
          interval: newInterval,
          product,
          subscriptionKey,
          scaleType,
        },
      ];
    });
    setShowCart(true);
  }, [cart]);

  const isButtonDisabled = useCallback(
    (product: Product, subscriptionKey: string) => {
      if (cart.length === 0) return false;

      const cartInterval = cart[0].interval;
      const productInterval = product.recurring?.interval as 'month' | 'year';
      if (productInterval !== cartInterval) return true;

      const isInCart = cart.some(item => item.priceId === product.id);
      if (isInCart) return true;

      if (subscriptionKey === 'app') {
        const productScaleType = product.lookup_key.toLowerCase().includes('single')
          ? 'single'
          : product.lookup_key.toLowerCase().includes('group')
          ? 'group'
          : null;

        const hasComboInCartForScale = cart.some(
          item =>
            item.subscriptionKey === 'app' &&
            item.product.lookup_key.toLowerCase().includes('combo') &&
            item.scaleType === productScaleType
        );
        if (hasComboInCartForScale) return true;
      }

      return false;
    },
    [cart]
  );

  const removeFromCart = useCallback((priceId: string) => {
    setCart(prev => prev.filter(item => item.priceId !== priceId));
  }, []);

  const updateQuantity = useCallback((priceId: string, newQuantity: number) => {
    if (newQuantity < 1) return;
    if (newQuantity > 10) {
      alert('Maximum 10 items per product');
      return;
    }
    setCart(prev =>
      prev.map(item =>
        item.priceId === priceId
          ? { ...item, quantity: newQuantity }
          : item
      )
    );
  }, []);

  const cartTotal = useMemo(() =>
    cart.reduce((sum, item) => {
      const price = item.product.billing_scheme === 'tiered'
        ? item.product.tiers?.[0]?.unit_amount || 0
        : item.product.unit_amount || 0;
      return sum + (price * item.quantity) / 100;
    }, 0),
    [cart]
  );

  const cartQuantity = useMemo(() =>
    cart.reduce((sum, item) => sum + item.quantity, 0),
    [cart]
  );

  const handleCheckout = useCallback(() => {
    if (cart.length === 0) {
      toaster.warning('Please add at least one product to the cart');
      return;
    }

    const fullName = `${registrationData.firstName} ${registrationData.lastName}`;
    const data = {
      email: registrationData.email,
      name: fullName,
      items: cart.map(item => ({
        price: item.priceId,
        quantity: item.quantity,
        interval: item.interval,
      })),
      saveUserData: userData,
      saveUserInfoData: userInfoData,
      saveBusinessInfoData: businessInfoData,
      saveBusinessData: businessData,
      saveAffiliateData: affiliateData,
      saveBathroomData: bathroomData,
    };
    dispatch(createSubscriptionTypes(data));
  }, [cart, registrationData, userData, userInfoData, businessInfoData, businessData, affiliateData, bathroomData, dispatch]);

  const calculateOriginalPrice = useCallback((discountedPrice: number) => {
    return (discountedPrice / (1 - 0.166667)).toFixed(2);
  }, []);

  const toggleTiers = useCallback((priceId: string) => {
    setExpandedTiers(prev => ({ ...prev, [priceId]: !prev[priceId] }));
  }, []);

  return (
    <Modal open={open} onClose={onClose} sx={{ backdropFilter: 'blur(3px)' }}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4,
          width: '95%',
          maxWidth: '1200px',
          maxHeight: '90vh',
          overflowY: 'auto',
          borderRadius: '16px',
          boxShadow: theme.shadows[10],
          border: `1px solid ${theme.palette.divider}`,
          background: theme.palette.background.paper,
        }}
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 4,
          position: 'relative',
          zIndex: 9999,
        }}>
          <Typography
            variant="h4"
            sx={{
              color: theme.palette.text.primary,
              fontWeight: 'bold',
              letterSpacing: '-0.5px',
            }}
          >
            Choose Your Plan
          </Typography>
          <IconButton
            onClick={toggleCart}
            sx={{
              position: 'relative',
              '&:hover': { backgroundColor: theme.palette.action.hover },
            }}
          >
            <Badge badgeContent={cartQuantity} color="primary">
              <ShoppingCartIcon sx={{ fontSize: 32 }} />
            </Badge>
          </IconButton>
          <Collapse
            in={showCart}
            sx={{
              position: 'absolute',
              right: 0,
              top: '100%',
              zIndex: 1,
              width: '400px',
              [theme.breakpoints.down('sm')]: { width: '100%' },
            }}
          >
            <Cart
              cart={cart}
              updateQuantity={updateQuantity}
              removeFromCart={removeFromCart}
              cartTotal={cartTotal}
              handleCheckout={handleCheckout}
              theme={theme}
            />
          </Collapse>
        </Box>

        <Grid container spacing={4}>
          {Object.entries(products).map(([subscriptionKey, subscriptionProducts]) => (
            <React.Fragment key={subscriptionKey}>
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  {subscriptionKey.charAt(0).toUpperCase() + subscriptionKey.slice(1)} Subscription
                </Typography>
              </Grid>
              {(['monthly', 'yearly'] as const).map(interval => (
                <Grid item xs={12} md={6} key={interval}>
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 3,
                      color: theme.palette.text.primary,
                      fontWeight: 600,
                      display: 'flex',
                      alignItems: 'center',
                      '&::before, &::after': {
                        content: '""',
                        flex: 1,
                        height: '1px',
                        backgroundColor: theme.palette.divider,
                      },
                      '&::before': { mr: 2 },
                      '&::after': { ml: 2 },
                    }}
                  >
                    {interval.charAt(0).toUpperCase() + interval.slice(1)} Plans
                  </Typography>
                  <Grid container spacing={3}>
                    {(subscriptionProducts[interval] || []).map((product: Product) => (
                      <Grid item xs={12} key={product.id}>
                        <ProductCard
                          product={product}
                          type={interval}
                          subscriptionKey={subscriptionKey}
                          expandedTiers={expandedTiers}
                          toggleTiers={toggleTiers}
                          addToCart={addToCart}
                          calculateOriginalPrice={calculateOriginalPrice}
                          disabled={isButtonDisabled(product, subscriptionKey)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </React.Fragment>
          ))}
        </Grid>
      </Paper>
    </Modal>
  );
};

export default SubscriptionModal;