import React, { useEffect, useState, useMemo } from 'react';
import { Box, Typography, CircularProgress, Container, Avatar } from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import {
    CreateActionModel,
    SaveBusinessInfoActionModel,
    SaveUserInfoActionModel,
    SaveBusinessActionModel,
    UserData,
  } from '../../store/app/user/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBusinessTypes,
  getRetrieveSession,
  getSubscriptionTypes,
  saveAffiliate,
} from '../../store/app/user/actions';
import { ApplicationState } from '../../store'
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash'

interface PersonalInfoProps {
    userData: UserData
    saveUserInfo: (user: SaveUserInfoActionModel) => void
    createUser: (model: CreateActionModel) => void
    saveBusinessInfo: (data: SaveBusinessInfoActionModel) => void
    saveBusiness: (data: SaveBusinessActionModel) => void
  }

// Custom styling for the CircularProgress
const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const RegistrationProcess: React.FC<PersonalInfoProps> = ({userData, saveUserInfo, createUser, saveBusiness, saveBusinessInfo}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showRedirectionMessage, setShowRedirectionMessage] = useState<boolean>(false);
  const { sessionData, loading, isApiError} = useSelector((state: ApplicationState) => state.user)
  const [savingUser, setSavingUser] = useState<boolean>(false)
  const [savingUserInfo, setSavingUserInfo] = useState<boolean>(false)
  const [savingBusiness, setSavingBusiness] = useState<boolean>(false)
  const [creatingUserAccount, setCreatingUserAccount] = useState<any>(null)
  const [sessionInformationData, setSessionInformationData] = useState<any>(null);

  let query = useQuery();
  const navigate = useNavigate()

  const sessionId = query.get('session_id');
  const dispatch = useDispatch()

  //let's redirect to login after all the process is good
  useEffect(() => {
    console.log(`Check sessionId : ${sessionId}`);
    if (sessionId) {
        dispatch(getRetrieveSession(sessionId))
    }
  }, [dispatch, sessionId]);

  // 2. Once sessionData is available, parse it and update userData, then create the user account.
  useEffect(() => {
    if (sessionData) {
        console.log(`Being called here 1`);
      // If sessionData is a string, parse it; otherwise, assume it's an object.
      const sessionObj = typeof sessionData === 'string' ? JSON.parse(sessionData) : sessionData;
      if (sessionObj.metadata) {
        // Parse metadata fields that were stringified
        const userDataFromSession = JSON.parse(sessionObj.metadata.user_data);
        const userInfoFromSession = JSON.parse(sessionObj.metadata.user_info_data);

        // Update userData with the data from session.
        let updatedUserData = { ...userData };
        updatedUserData.email = userDataFromSession.email;
        updatedUserData.mobile_number = userDataFromSession.mobile_number;
        // Assign user_info as needed (adjust based on your schema)
        updatedUserData.user_info = userInfoFromSession.user_info;

        // Call the createUser action
        createUser({ userData: updatedUserData, navigate });
        setSavingUser(true);
        // Save the whole session object for subsequent steps.
        setSessionInformationData(sessionObj);
      }
    }
  }, [sessionData]);

  // 3. Once the user has been created (loading.userData is false) and savingUser is true,
  // use the session metadata to save additional user info.
  useEffect(() => {
    if (!loading.userData && savingUser && sessionInformationData?.metadata) {
    console.log(`Being called here 2!`);
      const userInfoFromSession = JSON.parse(sessionInformationData.metadata.user_info_data);
      const userDataFromSession = JSON.parse(sessionInformationData.metadata.user_data);
      let updatedUser = _.cloneDeep(userData);
      updatedUser.email = userDataFromSession.email;
      updatedUser.user_info = userInfoFromSession.user_info; // contains first_name, last_name, etc.

      saveUserInfo({
        reqModel: {
          user_info: updatedUser.user_info!,
          user_id: updatedUser.id,
          user_type: updatedUser.user_type,
          email: updatedUser.email,
          mobile_number: updatedUser.mobile_number,
          language: 'en',
          country_code: updatedUser.country_code,
          username: updatedUser.username,
          login_type: updatedUser.user_info ? 'email' : 'phone',
        },
        navigate,
      });
      setSavingUser(false);
      setSavingUserInfo(true);
    }
  }, [savingUser, loading.userData]);

  // 4. Once user info has been saved (loading.userInfo is false) and savingUserInfo is true,
  // process business info from session metadata.
  useEffect(() => {
    if (!loading.userInfo && savingUserInfo && sessionInformationData?.metadata) {
        console.log(`Being called here 3!`);
        console.log(`Check userData3 : ${JSON.stringify(userData, null, 2)}`);
      const businessInfoFromSession = sessionInformationData.metadata.business_info_data
        ? JSON.parse(sessionInformationData.metadata.business_info_data)
        : null;
      if (businessInfoFromSession) {
        saveBusinessInfo({
          reqModel: {
            businessName: businessInfoFromSession.businessName,
            businessIndustry: businessInfoFromSession.businessIndustry,
            location: businessInfoFromSession.location, // Assuming location is structured as needed
            user_id: userData.id,
          },
          navigate,
        });
        setSavingUserInfo(false);
        setSavingBusiness(true);
      } else {
        // If no business info is provided, move on.
        setSavingUserInfo(false);
      }
    }
  }, [savingUserInfo, loading.userInfo, userData.user_info]);

  // 5. Finally, once the previous step is done and savingBusiness is true,
  // process business data (if available) for saving.
  useEffect(() => {
    if (!loading.userInfo && savingBusiness && sessionInformationData?.metadata) {
        console.log(`Being called here 4!`);
        console.log(`Check userData 4 : ${JSON.stringify(userData, null, 2)}`)
      const businessDataFromSession = sessionInformationData.metadata.business_data
        ? JSON.parse(sessionInformationData.metadata.business_data)
        : null;
      if (businessDataFromSession) {
        saveBusiness({
          reqModel: {
            business_name: businessDataFromSession.business_name,
            business_address: businessDataFromSession.business_address,
            user_id: userData.id,
          },
          navigate,
        });
      }
      setSavingBusiness(false);
    }
  }, [savingBusiness, loading.userInfo]);

  useEffect(() => {
    const checkRedirectionStatus = setTimeout(() => {
      setIsLoading(false); // Stop showing loading after 5 seconds
      setShowRedirectionMessage(true); // Show message if not redirected
    }, 5000);

    return () => clearTimeout(checkRedirectionStatus);
  }, []);

  return (
    <Container maxWidth="sm" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Logo style={{ width: '100px', height: '100px', marginBottom: '12px', display: 'block', }} />
      
      <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', textAlign: 'center' }}>
        Thank you for the subscription
      </Typography>
      {isLoading && (
        <>
          <Typography variant="subtitle1" gutterBottom sx={{ color: 'text.secondary', textAlign: 'center' }}>
            We are now processing your registration please kindly wait ...
          </Typography>
        </>
        )}
      {isLoading && <StyledCircularProgress sx={{ mt: 2 }} />}
      {!isLoading && showRedirectionMessage && (
        <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', mt: 2 }}>
          You will be automatically redirected . Please don't close this window.
        </Typography>
      )}
    </Container>
  );
};

export default RegistrationProcess;