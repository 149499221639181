import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Chip,
  IconButton,
  useTheme,
  TablePagination,
  TextField,
} from "@mui/material";
import { Check, Cancel, Lock, LockOpen, Visibility } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { getAllAffiliates, getSuperAdminAffiliateInformation, updateAffiliateStatus } from "../../../../store/app/user/actions";
import ConfirmationModal from "../../../component/ConfirmationModal";
import AffiliateDetailsInformationModal from "../components/modals/AffiliateDetailsInformationModal";
import { deleteSettings, getAllPayoutRequest, getSettings, saveSettings, updateSettings } from "../../../../store/app/super_admin/action";

interface Affiliate {
  id: string;
  name: string;
  email: string;
  commission_start_date: string;
  commission_end_date: string;
  total_earnings: number;
  status: 'ACTIVE' | 'PENDING' | 'SUSPENDED';
}

interface PayoutRequest {
  id: string;
  name: string;
  type: string;
  date: string;
  amount: string;
}

interface Settings {
  id: number; 
  key: string;
  value: string
}


const SuperAdminAffiliateDashboard: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { allAffiliateData, superAdminAffiliateData } = useSelector(
    (state: ApplicationState) => state.user
  );
  const {superAdminSettingsData, payoutRequestData} = useSelector(
    (state: ApplicationState) => state.superAdmin
  );
  
  const [editId, setEditId] = useState<string | null>(null);
  const [editValue, setEditValue] = useState<string>("");
  const [activeTab, setActiveTab] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState<'status' | 'commission' | null>(null);
  const [selectedAffiliate, setSelectedAffiliate] = useState<Affiliate | null>(null);

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalAffiliates, setTotalAffiliates] = useState(0);

  const [affiliates, setAffiliates] = useState<Affiliate[]>([]);

  const [payoutRequests, setPayoutRequests] = useState<PayoutRequest[]>([]);

    // State for affiliate details modal
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [activeAffiliatesCount, setActiveAffiliatesCount] = useState(0);
  const [suspendedAffiliatesCount, setSuspendedAffiliatesCount] = useState(0);

  //Manage
  const [settings, setSettings] = useState<Settings[]>([]);
  const [newSettingKey, setNewSettingKey] = useState('');
  const [newSettingValue, setNewSettingValue] = useState('');
  const [isAdding, setIsAdding] = useState(false);

   // State for the confirmation modal:
   const [confirmModalOpen, setConfirmModalOpen] = useState(false);
   const [confirmModalAction, setConfirmModalAction] = useState<"delete" | "update" | null>(null);
   const [pendingSetting, setPendingSetting] = useState<Settings | null>(null);
   // Backup storage for original values keyed by setting id:
   const [editingBackup, setEditingBackup] = useState<{ [id: number]: string }>({});

  useEffect(() => {
    const data = {
      page: page + 1,
      limit: rowsPerPage
    };
    dispatch(getAllAffiliates(data));
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    if (allAffiliateData?.affiliates) {

      const transformedAffiliates = allAffiliateData.affiliates.map((apiAffiliate: any) => ({
        id: apiAffiliate.id.toString(),
        name: `${apiAffiliate.first_name} ${apiAffiliate.last_name}`,
        email: apiAffiliate.email,
        // Map commission dates from the API. They will be ISO strings.
        commission_start_date: apiAffiliate.commission_start_date,
        commission_end_date: apiAffiliate.commission_end_date,
        total_earnings: apiAffiliate.total_earnings,
        status: (apiAffiliate.affiliate_status as 'ACTIVE' | 'PENDING' | 'SUSPENDED')
      }));
      setAffiliates(transformedAffiliates);
      setTotalAffiliates(allAffiliateData.total);
    }
  }, [allAffiliateData]);

  useEffect (() => {
    if(!payoutRequestData) {
      dispatch(getAllPayoutRequest(1, 10))
    }
    console.log(`Check payout request data: ${JSON.stringify(payoutRequestData)}`);
  }, [payoutRequestData, dispatch])


  useEffect(() => {
    const activeCount = affiliates.filter(a => a.status === 'ACTIVE').length;
    const suspendedCount = affiliates.filter(a => a.status === 'SUSPENDED').length;
  
    setActiveAffiliatesCount(activeCount);
    setSuspendedAffiliatesCount(suspendedCount);
  }, [affiliates]);


  const handleToggleStatus = (affiliate: Affiliate) => {
    setSelectedAffiliate(affiliate);
    setCurrentAction('status');
    setModalOpen(true);
  };

  const handleConfirmStatusToggle = () => {
    if (selectedAffiliate) {
      const updatedStatus = selectedAffiliate.status === 'ACTIVE' ? 'SUSPENDED' : 'ACTIVE';
      dispatch(updateAffiliateStatus(selectedAffiliate.id, updatedStatus));
      setAffiliates(affiliates.map(affiliate => 
        affiliate.id === selectedAffiliate.id 
          ? { ...affiliate, status: updatedStatus }
          : affiliate
      ));
      setModalOpen(false);
    }
  };

  const handleViewAffiliate = (id: string) => {
    dispatch(getSuperAdminAffiliateInformation(id))
    setDetailModalOpen(true);
  };

  const handleEditCancel = () => {
    setEditId(null);
    setEditValue("");
  };

  const handleEditSave = (id: string) => {
    const affiliate = affiliates.find(a => a.id === id);
    if (affiliate) {
      setSelectedAffiliate(affiliate);
      setCurrentAction('commission');
      setModalOpen(true);
    }
  };

  const handleConfirmCommissionChange = () => {
    if (selectedAffiliate) {
      const newPercentage = parseInt(editValue);
      if (!isNaN(newPercentage)) {
        setAffiliates(affiliates.map(affiliate =>
          affiliate.id === selectedAffiliate.id ? { 
            ...affiliate, 
            // Add commissionPercentage if needed.
            // commissionPercentage: newPercentage 
          } : affiliate
        ));
      }
      setEditId(null);
      setEditValue("");
    }
    setModalOpen(false);
  };

  const handleAccept = (id: string) => {
    setPayoutRequests(currentRequests => 
      currentRequests.filter(request => request.id !== id)
    );
  };

  const handleReject = (id: string) => {
    setPayoutRequests(currentRequests => 
      currentRequests.filter(request => request.id !== id)
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // Pagination handlers
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when changing rows per page
  };

  const getModalContent = () => {
    if (!selectedAffiliate) return { title: '', message: '' };
    
    if (currentAction === 'status') {
      const action = selectedAffiliate.status === 'ACTIVE' ? 'SUSPENDED' : 'ACTIVE';
      return {
        title: `Confirm Status Change`,
        message: `Are you sure you want to ${action} ${selectedAffiliate.name}?`,
      };
    }
    
    if (currentAction === 'commission') {
      return {
        title: `Confirm Commission Change`,
        message: `Are you sure you want to change commission for ${selectedAffiliate.name} to ${editValue}%?`,
      };
    }
    
    return { title: '', message: '' };
  };

  //Manage - Settins section

  useEffect(() => {
    dispatch(getSettings())
  }, [dispatch])

  useEffect(() => {
    // When superAdminSettingsData is updated, update local state
    if (superAdminSettingsData.length > 0) {
      setSettings(superAdminSettingsData);
    }
  }, [superAdminSettingsData]);

  // Insert new setting
  const handleSubmit = () => {
    if (!newSettingKey.trim() || !newSettingValue.trim()) return;
    dispatch(saveSettings(newSettingKey, newSettingValue));
    setNewSettingKey('');
    setNewSettingValue('');
    setIsAdding(false);
  };

  // Trigger update confirmation from the text field's onBlur:
  const triggerUpdate = (setting: Settings) => {
    setPendingSetting(setting);
    setConfirmModalAction("update");
    setConfirmModalOpen(true);
  };

  // Trigger delete confirmation:
  const triggerDelete = (id: number) => {
    const setting = settings.find((s) => s.id === id);
    if (setting) {
      setPendingSetting(setting);
      setConfirmModalAction("delete");
      setConfirmModalOpen(true);
    }
  };

  // On modal confirmation
  const handleConfirm = () => {
    if (confirmModalAction === "update" && pendingSetting) {
      dispatch(updateSettings(pendingSetting.key, pendingSetting.value));
      // Remove the backup entry for this setting after update:
      setEditingBackup((prev) => {
        const { [pendingSetting.id]: removed, ...rest } = prev;
        return rest;
      });
    }
    if (confirmModalAction === "delete" && pendingSetting) {
      dispatch(deleteSettings(pendingSetting.id));
    }
    setConfirmModalOpen(false);
    setPendingSetting(null);
    setConfirmModalAction(null);
  };

  // On cancel, if update was pending, revert the value using the backup.
  const handleCancel = () => {
    if (confirmModalAction === "update" && pendingSetting) {
      // Revert the value of the setting back to its original:
      const originalValue = editingBackup[pendingSetting.id];
      if (originalValue !== undefined) {
        setSettings((prev) =>
          prev.map((s) =>
            s.id === pendingSetting.id ? { ...s, value: originalValue } : s
          )
        );
        // Remove the backup entry.
        setEditingBackup((prev) => {
          const { [pendingSetting.id]: removed, ...rest } = prev;
          return rest;
        });
      }
    }
    setConfirmModalOpen(false);
    setPendingSetting(null);
    setConfirmModalAction(null);
  };

  useEffect(() => {
    console.log(`superAdminAffiliateData: ${JSON.stringify(superAdminAffiliateData)}`)
  }, [superAdminAffiliateData])


  return (
    <Box sx={{ 
      padding: "40px",
      backgroundColor: theme.palette.background.default,
      minHeight: "100vh"
    }}>
      <Box sx={{ 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 4
      }}>
        <Typography variant="h4" sx={{ 
          fontWeight: 700,
          color: theme.palette.secondary.main,
          fontSize: '2rem'
        }}>
          Super Admin Dashboard
        </Typography>
        <Chip 
          label="Super Admin View" 
          color="secondary" 
          variant="outlined"
        />
      </Box>

      <Paper sx={{ mb: 3 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Affiliates" />
          <Tab label="Payout Request" />
          <Tab label="Manage" />
        </Tabs>
      </Paper>

      {activeTab === 0 && (
        <>
          <Box sx={{ 
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
            gap: 3,
            mb: 4
          }}>
            <Paper sx={{ p: 3, borderRadius: '12px' }}>
              <Typography variant="body2" color="textSecondary">Total Affiliates</Typography>
              <Typography variant="h4">{allAffiliateData.total}</Typography>
            </Paper>
            <Paper sx={{ p: 3, borderRadius: '12px' }}>
              <Typography variant="body2" color="textSecondary">Active Affiliates</Typography>
              <Typography variant="h4">
                {activeAffiliatesCount}
              </Typography>
            </Paper>
            <Paper sx={{ p: 3, borderRadius: '12px' }}>
              <Typography variant="body2" color="textSecondary">Suspended Affiliates</Typography>
              <Typography variant="h4">
                {suspendedAffiliatesCount}
              </Typography>
            </Paper>
            {/* <Paper sx={{ p: 3, borderRadius: '12px' }}>
              <Typography variant="body2" color="textSecondary">Total Payouts</Typography>
              <Typography variant="h4">
                ${affiliates.reduce((sum, a) => sum + a.total_earnings, 0).toLocaleString()}
              </Typography>
            </Paper> */}
          </Box>

          <Paper sx={{ 
            borderRadius: '16px',
            overflow: 'hidden',
            boxShadow: theme.shadows[3]
          }}>
            <TableContainer>
              <Table>
                <TableHead sx={{ 
                  backgroundColor: theme.palette.grey[100],
                  borderBottom: `2px solid ${theme.palette.divider}`
                }}>
                  <TableRow>
                    {['Affiliate Name', 'Commission Start Date', 'Commission End Date', 'Total Earnings', 'Status', 'Actions'].map((header) => (
                      <TableCell key={header} sx={{ 
                        py: 2,
                        fontWeight: 600,
                        color: theme.palette.text.secondary
                      }}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {affiliates.map((affiliate) => (
                    <TableRow key={affiliate.id} hover>
                      <TableCell>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography fontWeight={500}>{affiliate.name}</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {affiliate.email}
                          </Typography>
                        </Box>
                      </TableCell>
                      
                      <TableCell>
                        <Typography>
                          {affiliate.commission_start_date
                            ? new Date(affiliate.commission_start_date).toLocaleDateString()
                            : 'N/A'}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography>
                          {affiliate.commission_end_date
                            ? new Date(affiliate.commission_end_date).toLocaleDateString()
                            : 'N/A'}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        ${affiliate.total_earnings ?? 0}
                      </TableCell>

                      <TableCell>
                        <Chip
                          label={affiliate.status}
                          color={
                            affiliate.status === 'ACTIVE' ? 'success' :
                            affiliate.status === 'PENDING' ? 'warning' : 'error'
                          }
                          size="small"
                          variant="outlined"
                        />
                      </TableCell>

                      <TableCell>
                        {editId === affiliate.id ? (
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <IconButton 
                              onClick={() => handleEditSave(affiliate.id)}
                              color="success"
                            >
                              <Check />
                            </IconButton>
                            <IconButton 
                              onClick={handleEditCancel}
                              color="error"
                            >
                              <Cancel />
                            </IconButton>
                          </Box>
                        ) : (
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <IconButton
                              onClick={() => handleViewAffiliate(affiliate.id)}
                              color="primary"
                            >
                              <Visibility />
                            </IconButton>
                            <IconButton
                              onClick={() => handleToggleStatus(affiliate)}
                              color={affiliate.status === 'ACTIVE' ? 'error' : 'success'}
                            >
                              {affiliate.status === 'ACTIVE' ? <Lock /> : <LockOpen />}
                            </IconButton>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Pagination */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalAffiliates}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                borderTop: `1px solid ${theme.palette.divider}`,
                '& .MuiTablePagination-toolbar': {
                  padding: '16px',
                }
              }}
            />
          </Paper>
        </>
      )}

      {activeTab === 1 && (
        <Paper sx={{ 
          borderRadius: '16px',
          overflow: 'hidden',
          boxShadow: theme.shadows[3],
          mt: 3
        }}>
          <TableContainer>
            <Table>
              <TableHead sx={{ 
                backgroundColor: theme.palette.grey[100],
                borderBottom: `2px solid ${theme.palette.divider}`
              }}>
                <TableRow>
                  <TableCell sx={{ py: 2, fontWeight: 600, color: theme.palette.text.secondary }}>Name</TableCell>
                  <TableCell sx={{ py: 2, fontWeight: 600, color: theme.palette.text.secondary }}>Date of Request</TableCell>
                  <TableCell sx={{ py: 2, fontWeight: 600, color: theme.palette.text.secondary }}>Type of Request</TableCell>
                  <TableCell sx={{ py: 2, fontWeight: 600, color: theme.palette.text.secondary }}>Amount</TableCell>
                  <TableCell sx={{ py: 2, fontWeight: 600, color: theme.palette.text.secondary }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payoutRequests.map((request) => (
                  <TableRow key={request.id} hover>
                    <TableCell>{request.name}</TableCell>
                    <TableCell>{new Date(request.date).toLocaleDateString()}</TableCell>
                    <TableCell>{request.type}</TableCell>
                    <TableCell>{request.amount}</TableCell>
                    <TableCell>
                      <Button 
                        variant="contained" 
                        color="success" 
                        size="small" 
                        onClick={() => handleAccept(request.id)}
                        sx={{
                          mr: 1,
                          backgroundColor: `${theme.palette.primary.main} !important`,
                          '&:hover': {
                            boxShadow: `0 4px 12px ${theme.palette.primary.light}`,
                            backgroundColor: `${theme.palette.primary.dark} !important`,
                          }
                        }}
                      >
                        Accept
                      </Button>
                      <Button 
                        variant="outlined" 
                        color="error" 
                        size="small" 
                        onClick={() => handleReject(request.id)}
                      >
                        Reject
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}

      {activeTab === 2 && (
        <Paper sx={{ p: 3, borderRadius: '16px', mt: 3 }}>
        {/* Add Setting Form */}
        <Box mb={4}>
          <Button 
          variant="contained" 
          color="success" 
          size="small" 
          onClick={() => setIsAdding(true)}
          sx={{
            mr: 1,
            backgroundColor: `${theme.palette.primary.main} !important`,
            '&:hover': {
              boxShadow: `0 4px 12px ${theme.palette.primary.light}`,
              backgroundColor: `${theme.palette.primary.dark} !important`,
            }
          }}
          >
            <Typography>Add New Setting</Typography>
          </Button>
          {isAdding && (
            <Box mt={2}>
              <TextField
                label="Key"
                fullWidth
                value={newSettingKey}
                onChange={(e) => setNewSettingKey(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Value"
                fullWidth
                value={newSettingValue}
                onChange={(e) => setNewSettingValue(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button 
                variant="contained" 
                color="success" 
                size="small" 
                onClick={() => handleSubmit()}
                sx={{
                  mr: 1,
                  backgroundColor: `${theme.palette.primary.main} !important`,
                  '&:hover': {
                    boxShadow: `0 4px 12px ${theme.palette.primary.light}`,
                    backgroundColor: `${theme.palette.primary.dark} !important`,
                  }
                }}
              >
                Save
              </Button>
              <Button color="error" onClick={() => setIsAdding(false)}>
                Cancel
              </Button>
            </Box>
          )}
        </Box>
    
        {/* Settings Table */}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell>Value</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {settings.map((setting) => (
                <TableRow key={setting.id}>
                  <TableCell>{setting.key}</TableCell>
                  <TableCell>
                    <TextField
                      value={setting.value}
                      fullWidth
                      onFocus={() =>
                        setEditingBackup((prev) => {
                          // If no backup exists for this setting, save the current value.
                          if (prev[setting.id] === undefined) {
                            return { ...prev, [setting.id]: setting.value };
                          }
                          return prev;
                        })
                      }
                      onChange={(e) =>
                        setSettings((prev) =>
                          prev.map((s) =>
                            s.id === setting.id ? { ...s, value: e.target.value } : s
                          )
                        )
                      }
                      onBlur={() => triggerUpdate(setting)}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      color="error"
                      onClick={() => triggerDelete(setting.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      )}

      {/** Modal for manage Section 0 */}
      <ConfirmationModal
        open={confirmModalOpen}
        title={confirmModalAction === "delete" ? "Confirm Deletion" : "Confirm Update"}
        message={
          confirmModalAction === "delete"
            ? `Are you sure you want to delete the setting "${pendingSetting?.key}"?`
            : `Are you sure you want to update the setting "${pendingSetting?.key}" to "${pendingSetting?.value}"?`
        }
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />

      {/** Modal for section 0 */}
      <ConfirmationModal
        open={modalOpen}
        onConfirm={() => {
          if (currentAction === 'status') handleConfirmStatusToggle();
          if (currentAction === 'commission') handleConfirmCommissionChange();
        }}
        onCancel={() => setModalOpen(false)}
        {...getModalContent()}
      />

      {/**Affiliate Details Information Modal */}
      <AffiliateDetailsInformationModal
        open={detailModalOpen}
        onClose={() => setDetailModalOpen(false)}
        data={superAdminAffiliateData}
      />
    </Box>
  );
};

export default SuperAdminAffiliateDashboard;