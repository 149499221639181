import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  styled,
  Typography,
  TablePagination,
} from "@mui/material";
import { Chip } from "@mui/material";

interface AffiliateDetailsInformationModalProps {
  open: boolean;
  onClose: () => void;
  data: any[];
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StatusChip = styled(Chip)(({ theme }) => ({
  fontWeight: 500,
  textTransform: 'capitalize',
  borderRadius: 4,
  fontSize: theme.typography.caption.fontSize,
}));

const AffiliateDetailsInformationModal: React.FC<AffiliateDetailsInformationModalProps> = ({
  open,
  onClose,
  data,
}) => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 3,
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          py: 2,
          px: 3,
        }}
      >
        <Typography variant="h6" component="div">
          Affiliate Detail Information
        </Typography>
      </DialogTitle>
      
      <DialogContent sx={{ p: 0 }}>
        <TableContainer>
          <Table sx={{ minWidth: 1200 }}>
            <TableHead>
              <TableRow>
                {[
                  'Username',
                  'User Type',
                  'Package Subscribed',
                  'Account Created',
                  'Subscription Created',
                  'Stripe Connected',
                  'Tsekmo Package',
                  'Registration Payout Date',
                  'Payout Status (Reg)',
                  'Payout Status (Sub)',
                ].map((header) => (
                  <TableCell
                    key={header}
                    sx={{
                      backgroundColor: theme.palette.grey[100],
                      fontWeight: 600,
                      py: 2,
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            
            <TableBody>
              {(rowsPerPage > 0
                ? (Array.isArray(data) ? data : []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : Array.isArray(data) ? data : []
              ).map((detail) => (
                <StyledTableRow key={detail.id} hover>
                  <TableCell sx={{ py: 1.5, minWidth: 120 }}>
                    <Typography variant="body2" fontWeight={500}>
                      {detail.username}
                    </Typography>
                  </TableCell>
                  
                  <TableCell sx={{ py: 1.5 }}>
                    <StatusChip
                      label={detail.user_type}
                      color="primary"
                      variant="outlined"
                      size="small"
                    />
                  </TableCell>
                  
                  <TableCell sx={{ py: 1.5 }}>
                    {detail.package_subscribed || "-"}
                  </TableCell>
                  
                  <TableCell sx={{ py: 1.5, whiteSpace: 'nowrap' }}>
                    {new Date(detail.account_created).toLocaleDateString()}
                  </TableCell>
                  
                  <TableCell sx={{ py: 1.5, whiteSpace: 'nowrap' }}>
                    {detail.subscription_created
                      ? new Date(detail.subscription_created).toLocaleDateString()
                      : "-"}
                  </TableCell>
                  
                  <TableCell sx={{ py: 1.5 }}>
                    <StatusChip
                      label={detail.stripe_account_connected ? 'Connected' : 'Not Connected'}
                      color={detail.stripe_account_connected ? 'success' : 'error'}
                      variant="filled"
                      size="small"
                    />
                  </TableCell>
                  
                  <TableCell sx={{ py: 1.5 }}>
                    {detail.tsekmo_packages_subscribe || "-"}
                  </TableCell>
                  
                  <TableCell sx={{ py: 1.5, whiteSpace: 'nowrap' }}>
                    {detail.registration_payout_date
                      ? new Date(detail.registration_payout_date).toLocaleDateString()
                      : "-"}
                  </TableCell>
                  
                  <TableCell sx={{ py: 1.5 }}>
                    <StatusChip
                      label={detail.payout_status.registration}
                      color={
                        detail.payout_status.registration === 'paid' 
                          ? 'success' 
                          : 'warning'
                      }
                      size="small"
                    />
                  </TableCell>
                  
                  <TableCell sx={{ py: 1.5 }}>
                    <StatusChip
                      label={detail.payout_status.subscription}
                      color={
                        detail.payout_status.subscription === 'paid' 
                          ? 'success' 
                          : 'warning'
                      }
                      size="small"
                    />
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            '& .MuiTablePagination-toolbar': {
              padding: '16px',
              backgroundColor: theme.palette.background.paper
            }
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AffiliateDetailsInformationModal;