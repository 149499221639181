import { all, call, fork, takeEvery, put } from 'redux-saga/effects'
import { ApiResponse, callApi } from '../../../app/utils/api'
import toaster from '../../../theme/components/Toaster'
import { getSettings, setAllPayoutRequest, setSettings } from './action'
import { SuperAdminTypes } from './types'


const paths = {
    getManageData: 'private/getManageData',
    insertManageData: 'private/insertManageData',
    updateManageData: 'private/updateManageData',
    deleteManageData: 'private/deleteManageData',
    payoutRequestDeclineAccept: 'private/payoutRequestDeclineAccept',
    getAllPayoutRequests: 'private/getAllPayoutRequests',
}

/**
 * Fetches settings from the database
 * @param {any} action - The action object containing necessary payload
 * @returns {Generator} - Redux-Saga generator
 */
function* handleGetSettings(action: any) {
  try {
    const res: ApiResponse = yield call(
        callApi, 
        'get', 
        paths.getManageData,
        undefined, //for body, query
        undefined, //for header
        undefined, //for params
        undefined, //for token
    )
    
    if (res.error) {
      toaster.error(`Unable to get data : ${res.error}`)
    } else {
      yield put(setSettings(res.data))
    }
  } catch (err) {
    toaster.error(`Unable to fetch settings : ${JSON.stringify(err)}`)
  }
}

/**
 * Saves a setting in the database
 * @param {key: string, value: string} action.payload
 * @returns {Promise<void>}
 */
function* handleSaveSettings(action: any) {
    try {
        const {key, value} = action.payload
        const res: ApiResponse = yield call(
            callApi, 
            'post', 
            paths.insertManageData,
            {
                key,
                value
            }, //for body, query
            undefined, //for header
            undefined, //for params
            undefined, //for token
        )
        
        if (res.error) {
            toaster.error(`Unable to save data : ${res.error}`)
        } else {
            toaster.success('Successfully Saved Settings')
            yield put(getSettings())
        }
      } catch (err) {
        toaster.error(`Unable to save settings : ${JSON.stringify(err)}`)
    }
}

/**
 * Updates a setting in the database
 * @param {key: string, value: string} action.payload
 * @returns {Promise<void>}
 */
function* handleUpdateSettings(action: any) {
    try {
        const {key, value} = action.payload
        const res: ApiResponse = yield call(
            callApi, 
            'put', 
            paths.updateManageData,
            {
                key,
                value
            }, //for body, query
            undefined, //for header
            undefined, //for params
            undefined, //for token
        )
        
        if (res.error) {
          toaster.error(`Unable to update data : ${res.error}`)
        } else {
          toaster.success('Successfully Updated Settings')
          yield put(getSettings());
        }
      } catch (err) {
        toaster.error(`Unable to update settings : ${JSON.stringify(err)}`)
    }
}

/**
 * Deletes a setting from the database
 * @param {id: number} action.payload
 * @returns {Promise<void>}
 */
function* handleDeleteSettings(action: any) {
    try {
        const {id} = action.payload
        const res: ApiResponse = yield call(
            callApi, 
            'delete', 
            `${paths.deleteManageData}/${id}`,
            undefined, //for body, query
            undefined, //for header
            undefined, //for params
            undefined, //for token
        )
        
        if (res.error) {
          toaster.error(`Unable to delete data : ${res.error}`)
        } else {
          toaster.success('Successfully Deleted Settings')
          yield put(getSettings());
        }
      } catch (err) {
        toaster.error(`Unable to delete settings : ${JSON.stringify(err)}`)
    }
}


function* handlePayoutAcceptDeclineRequest(action: any) {
    try {
        const {status, id} = action.payload
        const res: ApiResponse = yield call(
            callApi, 
            'put', 
            paths.payoutRequestDeclineAccept,
            {
                status,
                id
            }, //for body, query
            undefined, //for header
            undefined, //for params
            undefined, //for token
        )
        
        if (res.error) {
            toaster.error(`Unable to accept/decline payout request : ${res.error}`)
        } else {
            toaster.success('Successfully Action')
            // yield put(getSettings())
        }
      } catch (err) {
        toaster.error(`Unable to accept/decline payout request : ${JSON.stringify(err)}`)
    }
}

function* handleGetAllPayoutRequest(action: any) {
    try {
      const {page, limit} = action.payload
      const res: ApiResponse = yield call(
          callApi, 
          'get', 
          paths.getAllPayoutRequests,
          {
            page,
            limit
          }, //for body, query
          undefined, //for header
          undefined, //for params
          undefined, //for token
      )
      
      if (res.error) {
        toaster.error(`Unable to get payout data : ${res.error}`)
      } else {
        yield put(setAllPayoutRequest(res.data));
      }
    } catch (err) {
      toaster.error(`Unable to fetch payout data : ${JSON.stringify(err)}`)
    }
  }

/**
 * Watches for every GET_SUPER_ADMIN action and calls handleGetSettings when one is received
 * @function watchGetSettings
 * @return {void}
 */
function* watchGetSettings () {
  yield takeEvery(SuperAdminTypes.GET_SUPER_ADMIN, handleGetSettings);
}

/**
 * Watches for every SAVE_SETTINGS action and calls handleSaveSettings when one is received
 * @function watchSaveSettings
 * @return {void}
 */
function* watchSaveSettings () {
    yield takeEvery(SuperAdminTypes.SAVE_SETTINGS, handleSaveSettings)
}

/**
 * Watches for every UPDATE_SETTINGS action and calls handleUpdateSettings when one is received
 * @function watchUpdateSettings
 * @return {void}
 */
function* watchUpdateSettings () {
    yield takeEvery(SuperAdminTypes.UPDATE_SETTINGS, handleUpdateSettings)
}

/**
 * Watches for every DELETE_SETTINGS action and calls handleDeleteSettings when one is received
 * @function watchDeleteSettings
 * @returns {void}
 */
function* watchDeleteSettings () {
    yield takeEvery(SuperAdminTypes.DELETE_SETTINGS, handleDeleteSettings)
}

function* watchPayoutAcceptDeclineRequest () {
    yield takeEvery(SuperAdminTypes.PAYOUT_ACCEPT_DECLINE_REQUEST, handlePayoutAcceptDeclineRequest)
}

function* watchGetAllPayoutRequest () {
    yield takeEvery(SuperAdminTypes.GET_ALL_PAYOUT_REQUEST, handleGetAllPayoutRequest)
}

function* superAdminSagas() {
  yield all([
    fork(watchGetSettings),
    fork(watchSaveSettings),
    fork(watchUpdateSettings),
    fork(watchDeleteSettings),
    fork(watchPayoutAcceptDeclineRequest),
    fork(watchGetAllPayoutRequest),
  ])
}

export default superAdminSagas