import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography, 
  Divider, 
  Box, 
  TextField
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { CheckCircleOutline, WarningAmber } from '@mui/icons-material';

interface TermsModalProps {
  open: boolean;
  onClose: () => void;
  onAccept: (legalName: string) => void;
  theme: Theme;
}

const TermsModal: React.FC<TermsModalProps> = ({ open, onClose, onAccept, theme }) => {
  const [name, setName] = useState<string>('');

  const handleAccept = () => {
    if (name.trim()) {
     console.log(`Check name : ${JSON.stringify(name)}`);
      onAccept(name);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          width: '800px',
          maxWidth: '100%'
        }
      }}
    >
      <DialogTitle sx={{ 
        fontSize: '1.5rem',
        fontWeight: 700,
        bgcolor: theme.palette.grey[50],
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        Tsekmo Affiliate &amp; Referral Program Terms and Conditions
      </DialogTitle>
      <DialogContent 
        dividers
        sx={{ 
          py: 3,
          maxHeight: '70vh',
          overflowY: 'auto'
        }}
      >
        <Box sx={{ 
            p: 2,
            mb: 2,
            borderRadius: '8px',
            bgcolor: theme.palette.warning.light,
            display: 'flex',
            gap: 2
            }}>
            <WarningAmber sx={{ color: theme.palette.warning.main }} />
            <Typography variant="body2" sx={{ color: theme.palette.warning.dark }}>
                Important: Please read and understand our affiliate agreement carefully before activation.
            </Typography>
        </Box>
        {/* Your Terms and Conditions content goes here */}
        <Typography variant="body1" gutterBottom>
          Effective Date: 02/18/2024
        </Typography>
        <Typography variant="body1" gutterBottom>
          These Terms and Conditions govern the participation in the Tsekmo Affiliate and Referral Program (the "Program") for individuals and businesses (each a “Participant”) who sign up and engage in the Program. By participating in the Program, you agree to be bound by these Terms and Conditions, and any changes made to them.
        </Typography>
        <Divider sx={{ my: 2 }} />
        {/* 1. Eligibility */}
        <Typography variant="h6" gutterBottom>
          1. Eligibility
        </Typography>
        <Typography component="div" gutterBottom>
          To participate in the Affiliate and Referral Program, you must:
          <Box component="ul" sx={{ 
                pl: 2,
                '& li': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mb: 1,
                    marginTop: '5px',
                }
            }}>
            <li>
                <CheckCircleOutline 
                    color="success"  
                    fontSize="small" 
                    sx={{ fontSize: '15px' }} 
                />
                <Typography component="span">Be at least <strong>18 years old</strong>.</Typography>
            </li>
            <li>
                <CheckCircleOutline 
                    color="success"  
                    fontSize="small" 
                    sx={{ fontSize: '15px' }} 
                />
                <Typography component="span">Sign up as either a <strong>Customer</strong> or <strong>Business</strong> on the Tsekmo platform.</Typography>
            </li>
            <li>
                <CheckCircleOutline 
                    color="success"  
                    fontSize="small" 
                    sx={{ fontSize: '15px' }} 
                />
                <Typography component="span">Be legally able to form a binding contract in your jurisdiction.</Typography>
            </li>
            <li>
                <CheckCircleOutline 
                    color="success"  
                    fontSize="small" 
                    sx={{ fontSize: '15px' }} 
                />
                <Typography component="span">Provide accurate and complete registration information.</Typography>
            </li>
          </Box>
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 2. Affiliate Program Overview */}
        <Typography variant="h6" gutterBottom>
          2. Affiliate Program Overview
        </Typography>
        <Typography component="div" gutterBottom>
            The Tsekmo Affiliate Program allows registered affiliates (the "Affiliates") to earn <strong>commissions</strong> by referring new customers and businesses to Tsekmo via unique affiliate links.
          <Box component="ul" sx={{ 
                pl: 2,
                '& li': {
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 1,
                    mb: 1,
                    marginTop: '5px',
                }
            }}>
            <li>
                <CheckCircleOutline 
                    color="success"  
                    fontSize="small" 
                    sx={{ fontSize: '15px', marginTop: '5px' }} 
                />
                <Typography component="span">
                    <strong>Affiliates</strong> will be assigned a unique <strong>Affiliate Link</strong> upon registration.
                </Typography>
            </li>
            <li>
                <CheckCircleOutline 
                    color="success"  
                    fontSize="small" 
                    sx={{ fontSize: '15px', marginTop: '5px' }} 
                />
                <Typography component="span">
                    <strong>Commissions</strong> will be earned based on sales made through your <strong>Affiliate Link</strong>, as per the compensation structure outlined in the Affiliate Dashboard.
                </Typography>
            </li>
            <li>
                <CheckCircleOutline 
                    color="success"  
                    fontSize="small" 
                    sx={{ fontSize: '15px', marginTop: '5px' }} 
                />
                <Typography component="span">
                    Affiliates can earn up to <strong>40%</strong> of the <strong>one-year subscription</strong> fee for each platform subscription purchased by a referred entity, and <strong>40%</strong> of the purchase price of Tsekmo’s products, such as the <strong>basic monetization</strong> tool and the <strong>Bathroom Anti-Vandalism app</strong>.
                </Typography>
            </li>
          </Box>
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 3. Referral Program Overview */}
        <Typography variant="h6" gutterBottom>
          3. Referral Program Overview
        </Typography>
        <Typography component="div" gutterBottom>
          The Tsekmo Referral Program allows existing customers or businesses to earn rewards by referring new users (customers or businesses) to Tsekmo via a unique referral code or link.
          <Box component="ul" sx={{ 
                pl: 2,
                '& li': {
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 1,
                    mb: 1,
                    marginTop: '5px',
                }
            }}>
            <li>
                <CheckCircleOutline 
                    color="success"  
                    fontSize="small" 
                    sx={{ fontSize: '15px', marginTop: '5px' }} 
                />
                <Typography component="span">
                    <strong>Referrers</strong> can earn rewards, including <strong>discounts, free access to premium features, or prizes</strong>. 
                </Typography>
            </li>
            <li>
                <CheckCircleOutline 
                    color="success"  
                    fontSize="small" 
                    sx={{ fontSize: '15px', marginTop: '5px' }} 
                />
                <Typography component="span">
                    The referrer earns <strong>referral rewards</strong> when the referred customer/business successfully signs up and completes their account setup.
                </Typography>
            </li>
          </Box>
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 4. Tiered Earnings Structure */}
        <Typography variant="h6" gutterBottom>
          4. Tiered Earnings Structure
        </Typography>
        <Typography component="div" gutterBottom>
          <strong>-</strong>The Program consists of two tiers of earnings for both affiliates and referrers:
          <Box component="ul" sx={{ 
                pl: 2,
                '& li': {
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 1,
                    mb: 1
                }
            }}>
            <li>
                <CheckCircleOutline 
                    color="success"  
                    fontSize="small" 
                    sx={{ fontSize: '15px', marginTop: '5px' }} 
                />
                <Typography component="span">
                    <strong>Tier 1: Platform Subscriptions</strong> - Earn up to 40% of the purchase price for each platform subscription (including paid services) purchased by a referred entity.
                </Typography>
            </li>
            
            <li>
                <CheckCircleOutline 
                    color="success"  
                    fontSize="small" 
                    sx={{ fontSize: '15px', marginTop: '5px' }} 
                />
                <Typography component="span">
                    <strong>Tier 2: Product Purchases</strong> – Earn up to 40% of the purchase price for Tsekmo products, including basic monetization tools and the Bathroom Anti-Vandalism app.
                </Typography>
            </li>
          </Box>
          <strong>-</strong> Commissions will be paid on a recurring basis for each one-year subscription term. Payment for commissions is subject to the entity maintaining an active subscription and product use.
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 5. Registration Process */}
        <Typography variant="h6" gutterBottom>
          5. Registration Process
        </Typography>
        <Typography component="div" gutterBottom>
        <strong>-</strong> To join the Affiliate Program, you must 
          <Box component="ul" sx={{ 
                pl: 2,
                '& li': {
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 1,
                    mb: 1
                }
            }}>
            <li>
                <CheckCircleOutline 
                    color="success"  
                    fontSize="small" 
                    sx={{ fontSize: '15px', marginTop: '5px' }} 
                />
                <Typography component="span">
                    Complete the registration process on the Tsekmo website.
                </Typography>
            </li>
            <li>
                <CheckCircleOutline 
                    color="success"  
                    fontSize="small" 
                    sx={{ fontSize: '15px', marginTop: '5px' }} 
                />
                <Typography component="span">
                    Provide valid personal or business information (e.g., email, name, address).
                </Typography>
            </li>
            <li>
                <CheckCircleOutline 
                    color="success"  
                    fontSize="small" 
                    sx={{ fontSize: '15px', marginTop: '5px' }} 
                />
                <Typography component="span">
                    Agree to these Terms and Conditions and confirm that you have read and understood them.
                </Typography>
            </li>
          </Box>
          <strong>-</strong> To join the Referral Program, you must:
          <Box component="ul" sx={{ 
                pl: 2,
                '& li': {
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 1,
                    mb: 1
                }
            }}>
            <li>
                <CheckCircleOutline 
                    color="success"  
                    fontSize="small" 
                    sx={{ fontSize: '15px', marginTop: '5px' }} 
                />
                <Typography component="span">
                    Be an existing Customer or Business user of Tsekmo.
                </Typography>
            </li>
            <li>
                <CheckCircleOutline 
                    color="success"  
                    fontSize="small" 
                    sx={{ fontSize: '15px', marginTop: '5px' }} 
                />
                <Typography component="span">
                    Use your unique referral code/link to refer new users.
                </Typography>
            </li>
          </Box>
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 6. Affiliate Program Compensation and Payment */}
        <Typography variant="h6" gutterBottom>
          6. Affiliate Program Compensation and Payment
        </Typography>
        <Typography component="div" gutterBottom>
            <Typography paragraph>
                <strong>-</strong> Affiliates will receive a commission percentage for every valid sale made through their affiliate link, according to the specific program’s compensation plan.
            </Typography>
            <Box component="ul" sx={{ 
                    pl: 2,
                    '& li': {
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 1,
                        mb: 1
                    }
                }}>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        <strong>Tier 1: Platform Subscriptions</strong> – Up to 40% commission on the purchase price of each one-year platform subscription.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        <strong>Tier 2: Product Purchases</strong> – Up to 40% commission on the purchase price of Tsekmo’s products, such as the basic monetization tool and bathroom anti-vandalism app.
                    </Typography>
                </li>
            </Box>
            <Typography paragraph>
                <strong>-</strong> Commissions will be paid on a monthly basis, once the minimum payout threshold has been met.
            </Typography>
            <Typography paragraph>
                <strong>-</strong> Payments will be made via PayPal or another Tsekmo-approved payment method.
            </Typography>
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 7. Referral Program Rewards and Payment */}
        <Typography variant="h6" gutterBottom>
          7. Referral Program Rewards and Payment
        </Typography>
        <Typography component="div" gutterBottom>
            <Typography paragraph>
                <strong>-</strong> Referrers will earn rewards based on the successful sign-up of new users using their referral link or code.
            </Typography>
            <Typography paragraph>
                <strong>-</strong> Rewards for referrers include:
            </Typography>
            <Box component="ul" sx={{ 
                    pl: 2,
                    '& li': {
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 1,
                        mb: 1
                    }
                }}>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        <strong>Tier 1: Platform Subscriptions</strong> – Up to 40% of the purchase price of the referred entity’s one-year platform subscription.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        <strong>Tier 2: Product Purchases</strong> – Up to 40% of the purchase price of Tsekmo products purchased by the referred entity.
                    </Typography>
                </li>
            </Box>
            <Typography paragraph>
                <strong>-</strong> Rewards will be credited to the referrer's account once the referred user completes their registration and qualifies for the program.
            </Typography>
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 8. Prohibited Practices */}
        <Typography variant="h6" gutterBottom>
          8. Prohibited Practices
        </Typography>
        <Typography component="div" gutterBottom>
            Affiliates and Referrers must adhere to the following rules and guidelines:
          <Box component="ul" sx={{ 
                    pl: 2,
                    '& li': {
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 1,
                        mb: 1
                    }
                }}>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        No Fraudulent or Deceptive Marketing: Affiliates and referrers must not use false claims, misleading testimonials, or deceptive tactics to promote Tsekmo.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                    No Spam: Affiliates and referrers must not engage in unsolicited email marketing, excessive social media tagging, or any other form of spam.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                    No Paid Search: Affiliates may not use paid advertising (e.g., Google Ads, Facebook Ads) to promote their affiliate link without prior written approval from Tsekmo.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                    No Brand Hijacking: Affiliates and referrers cannot use Tsekmo’s trademarks or brand names in a manner that would confuse customers, such as creating ads that appear to be official Tsekmo promotions.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                    No Illegal Activities: Affiliates and referrers must comply with all applicable laws and regulations, including data privacy laws and advertising guidelines (e.g., FTC’s guidelines for affiliate marketing).
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                    Transparency: Affiliates and referrers must clearly disclose their relationship with Tsekmo, including using phrases such as "affiliate link" or "sponsored" where applicable.
                    </Typography>
                </li>
            </Box>
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 9. Termination of Participation */}
        <Typography variant="h6" gutterBottom>
          9. Termination of Participation
        </Typography>
        <Typography component="div" gutterBottom>
            Tsekmo reserves the right to terminate or suspend any participant’s access to the Program if the following occurs:
            <Box component="ul" sx={{ 
                    pl: 2,
                    '& li': {
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 1,
                        mb: 1
                    }
                }}>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                    Violation of these Terms and Conditions.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                    Engagement in prohibited marketing practices.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                    Inactivity for a period of 6 months or more.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                    Fraudulent or unethical behavior, including misrepresentation of Tsekmo’s products.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                    Affiliate or referral account termination will result in the forfeiture of any unpaid commissions or rewards.
                    </Typography>
                </li>
            </Box>
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 10. Payment and Earnings Disputes */}
        <Typography variant="h6" gutterBottom>
          10. Payment and Earnings Disputes
        </Typography>
        <Typography component="div" gutterBottom>
            <Box component="ul" sx={{ 
                    pl: 2,
                    '& li': {
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 1,
                        mb: 1
                    }
                }}>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                    In the event of a discrepancy or dispute regarding earnings, the participant must notify Tsekmo in writing within 30 days from the date of the alleged issue.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                    Tsekmo will investigate and resolve the dispute at its sole discretion.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                    All affiliate earnings are final once paid, unless found to be in error due to fraud, chargebacks, or other violations.
                    </Typography>
                </li>
            </Box>
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 11. Intellectual Property */}
        <Typography variant="h6" gutterBottom>
          11. Intellectual Property
        </Typography>
        <Typography component="div" gutterBottom>
            <Box component="ul" sx={{ 
                    pl: 2,
                    '& li': {
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 1,
                        mb: 1
                    }
                }}>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                    Affiliates and referrers are granted a non-exclusive, revocable license to use Tsekmo’s promotional materials (such as logos, banners, and links) for the sole purpose of promoting Tsekmo in accordance with these Terms and Conditions.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                    Affiliates and referrers agree not to modify, copy, or distribute any Tsekmo trademarks, materials, or content outside the scope of this Program.
                    </Typography>
                </li>
            </Box>
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 12. Privacy and Data Collection */}
        <Typography variant="h6" gutterBottom>
          12. Privacy and Data Collection
        </Typography>
        <Typography component="div" gutterBottom>
            <Box component="ul" sx={{ 
                    pl: 2,
                    '& li': {
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 1,
                        mb: 1
                    }
                }}>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        Tsekmo collects personal data of participants in accordance with its Privacy Policy.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        Affiliates and referrers consent to the use of personal data in connection with the Program, including communication and promotional purposes.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        You agree to comply with data protection laws and not share personal information of referred individuals without their consent.
                    </Typography>
                </li>
            </Box>
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 13. Limitation of Liability */}
        <Typography variant="h6" gutterBottom>
          13. Limitation of Liability
        </Typography>
        <Typography component="div" gutterBottom>
            <Typography paragraph>
                <strong>-</strong> Tsekmo is not liable for any direct, indirect, incidental, special, or consequential damages arising from participation in the Program, including but not limited to:
            </Typography>
            <Box component="ul" sx={{ 
                    pl: 2,
                    '& li': {
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 1,
                        mb: 1
                    }
                }}>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        Earnings loss due to termination or suspension of the Program.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        Use of affiliate/referral links by third parties.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        Errors in payment processing.
                    </Typography>
                </li>
            </Box>
            <Typography paragraph>
                <strong>-</strong> Tsekmo makes no warranties or representations about the potential success or profitability of participating in the Program.
            </Typography>
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 14. Changes to the Program */}
        <Typography variant="h6" gutterBottom>
          14. Changes to the Program
        </Typography>
        <Typography component="div" gutterBottom>
            <Box component="ul" sx={{ 
                    pl: 2,
                    '& li': {
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 1,
                        mb: 1
                    }
                }}>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        Tsekmo reserves the right to modify, suspend, or terminate the Affiliate and Referral Program at any time, without prior notice. Any changes will be communicated via email or on the Tsekmo website.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        Continued participation in the Program after changes constitutes acceptance of the updated Terms and Conditions.
                    </Typography>
                </li>
            </Box>
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 15. Governing Law and Dispute Resolution */}
        <Typography variant="h6" gutterBottom>
          15. Governing Law and Dispute Resolution
        </Typography>
        <Typography component="div" gutterBottom>
            <Box component="ul" sx={{ 
                    pl: 2,
                    '& li': {
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 1,
                        mb: 1
                    }
                }}>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        These Terms and Conditions will be governed by and construed in accordance with the laws of the state of Delaware, United States, without regard to its conflict of law provisions.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        Any disputes arising from or related to the Program will be resolved through binding arbitration in accordance with the rules of the American Arbitration Association
                    </Typography>
                </li>
            </Box>
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 16. Contact Information */}
        <Typography variant="h6" gutterBottom>
          16. Contact Information
        </Typography>
        <Typography component="div" gutterBottom>
          If you have any questions or need assistance regarding the Program, please contact Tsekmo’s Affiliate &amp; Referral Program Support at: 
          <Box component="ul" sx={{ 
                    pl: 2,
                    '& li': {
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 1,
                        mb: 1
                    }
                }}>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        Email : support@tsekmo.com.
                    </Typography>
                </li>
            </Box>
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 17. Agreement and Acceptance */}
        <Typography variant="h6" gutterBottom>
          17. Agreement and Acceptance
        </Typography>
        <Typography component="div" gutterBottom>
            By signing up for the Affiliate &amp; Referral Program, you acknowledge that you have read, understood, and agree to abide by these Terms and Conditions, as well as any amendments or updates to the Program that may be made from time to time.
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 18. Independent Contractors */}
        <Typography variant="h6" gutterBottom>
          18. Independent Contractors
        </Typography>
        <Typography component="div" gutterBottom>
            Affiliates and referrers are independent contractors and are not employees or agents of Tsekmo. Nothing in these Terms and Conditions should be construed as creating an employer-employee relationship, partnership, joint venture, or any other form of partnership.
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 19. Entire Agreement */}
        <Typography variant="h6" gutterBottom>
          19. Entire Agreement
        </Typography>
        <Typography component="div" gutterBottom>
            These Terms and Conditions, together with any applicable documents or policies incorporated by reference (such as the Privacy Policy), constitute the entire agreement between you and Tsekmo regarding your participation in the Affiliate and Referral Program. If any provision of these Terms and Conditions is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 20. Severability */}
        <Typography variant="h6" gutterBottom>
          20. Severability
        </Typography>
        <Typography component="div" gutterBottom>
            If any part of these Terms and Conditions is found to be invalid, void, or unenforceable for any reason, the remaining parts will continue in full force and effect.
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 21. Force Majeure */}
        <Typography variant="h6" gutterBottom>
          21. Force Majeure
        </Typography>
        <Typography component="div" gutterBottom>
            Tsekmo shall not be liable for any failure or delay in the performance of its obligations under these Terms and Conditions, including any delay or failure in the payment of commissions or rewards, caused by factors beyond its reasonable control, including but not limited to acts of God, war, terrorism, strikes, labor disputes, or technical failures.
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 22. Termination of Agreement */}
        <Typography variant="h6" gutterBottom>
          22. Termination of Agreement
        </Typography>
        <Typography component="div" gutterBottom>
            Either Tsekmo or the Affiliate/Referrer may terminate the participation in the Program at any time. Upon termination:
            <Box component="ul" sx={{ 
                    pl: 2,
                    '& li': {
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 1,
                        mb: 1
                    }
                }}>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        Affiliates and referrers must cease using any Tsekmo-related promotional materials and links.
                    </Typography>
                </li>
                <li>
                    <CheckCircleOutline 
                        color="success"  
                        fontSize="small" 
                        sx={{ fontSize: '15px', marginTop: '5px' }} 
                    />
                    <Typography component="span">
                        Any unpaid commissions or rewards may be forfeited, depending on the cause of termination (fraud, violation of terms, etc.).
                    </Typography>
                </li>
            </Box>
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 23. Promotional Material Use */}
        <Typography variant="h6" gutterBottom>
          23. Promotional Material Use
        </Typography>
        <Typography component="div" gutterBottom>
            Affiliates and referrers are only permitted to use promotional materials provided by Tsekmo or approved materials. Unauthorized use of promotional content, especially claims that are false, misleading, or violate advertising regulations, will lead to immediate termination from the Program.
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 24. No Guarantee of Earnings */}
        <Typography variant="h6" gutterBottom>
          24. No Guarantee of Earnings
        </Typography>
        <Typography component="div" gutterBottom>
            Tsekmo does not guarantee a specific income or level of earnings for Affiliates or Referrers. Earnings are based on the number of valid sales and referrals made, which can vary depending on the actions of customers and businesses referred to Tsekmo.
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 25. Taxes */}
        <Typography variant="h6" gutterBottom>
          25. Taxes
        </Typography>
        <Typography component="div" gutterBottom>
            Affiliates and referrers are solely responsible for paying any taxes on the commissions or rewards earned through their participation in the Program. Tsekmo will issue tax forms (e.g., IRS Form 1099) where applicable.
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 26. Dispute Resolution */}
        <Typography variant="h6" gutterBottom>
          26. Dispute Resolution
        </Typography>
        <Typography component="div" gutterBottom>
            Any disputes that arise in connection with this Program will first be attempted to be resolved through informal negotiation. If informal negotiation fails, the dispute will be resolved by binding arbitration, as described in section 15 above.
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* 27. Updates to Terms and Conditions */}
        <Typography variant="h6" gutterBottom>
          27. Updates to Terms and Conditions
        </Typography>
        <Typography component="div" gutterBottom>
            Tsekmo reserves the right to update or modify these Terms and Conditions at any time. Affiliates and Referrers will be notified via email or through the Tsekmo website about significant changes. Continued participation in the Program after the changes are announced will constitute acceptance of the new Terms and Conditions.
        </Typography>
        <Divider sx={{ my:2 }} />
        <Typography variant="body2" gutterBottom>
          By participating in the Tsekmo Affiliate &amp; Referral Program, you confirm that you have read, understood, and agree to these Terms and Conditions.
        </Typography>

        <TextField
            fullWidth
            label="Legal Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            sx={{ mt: 3 }}
            InputProps={{
                sx: { borderRadius: '8px' }
            }}
        />
      </DialogContent>
      <DialogActions sx={{ 
        px: 3,
        py: 2,
        borderTop: `1px solid ${theme.palette.divider}`
      }}>
        <Button 
          onClick={onClose}
          sx={{ 
            borderRadius: '8px',
            px: 3,
            textTransform: 'none'
          }}
        >
          Cancel
        </Button>
        <Button 
          variant="contained" 
          onClick={handleAccept}
          disabled={!name.trim()}
          sx={{
            borderRadius: '8px',
            px: 4,
            textTransform: 'none',
            fontWeight: 600,
            boxShadow: 'none',
            backgroundColor: `${theme.palette.primary.main} !important`,
            '&:hover': {
              boxShadow: `0 4px 12px ${theme.palette.primary.light}`,
              backgroundColor: `${theme.palette.primary.dark} !important`,
            }
          }}
        >
          I Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsModal;