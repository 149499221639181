import _ from 'lodash'
import { Reducer } from 'redux'
import { AppActionTypes } from '../types'
import { UserActionTypes, UserData, UserState, UserType } from './types'

// Type-safe initialState!
export const initialState: UserState = {
  userData: {
    user_type: UserType.BUSINESS_OWNER_WITHOUT_SUBSCRIPTION,
    country_code: 'us',
    is_valid: false,
    is_verified: false,
    hasMultipleRegistration: false,
    user_discounts: {
      id:0,
      user_id: 0,
      discount_data: '',
    },
  },
  isValidUser: false,
  flagDetails: [],
  loading: {
    userData: false,
    userInfo: false,
    useBathroom: false,
    infoData: false,
  },
  stripeDetails: {
    client_secret: '',
    customer_id: '',
    setup_intent_id: '',
    price_model: [],
  },
  isConatctInfoUpdatedSucess: false,
  loginCheckUserTypeData: {
    mobile_number: '',
    email: '',
  },
  isZipCodeEligible: true,
  businessTypes: [],
  specificUser: {},
  productList: {
    platform: { monthly: [], yearly: [] },
    app: { monthly: [], yearly: [] },
  },
  userBusinessStripeData: {
    business_name: '',
    created_at: new Date(),
    updated_at: new Date(),
    stripe_account_id: '',
    address: '',
    user_id: 0,
    delete_flag: false,
  },
  userRetrieveSubscriptionData: {},
  bathroomData: {},
  bathroomFetchError: false,
  stripeOnboardingStatus: {
    onboard_complete: false,
    is_need_requirement: false,
  },
  limitations: {},
  isApiError: false,
  sugesstionUsername: {},
  affiliateData:{},
  affiliateLink: '',
  chatData: [],
  specificChatData: {},
  specificChatRoomData: {},
  subscriptionTypes: {},
  allAffiliateData: {},
  sessionData: {},
  superAdminAffiliateData: {},
}

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<UserState> = (state = initialState, action) => {
  let newState = { ...state }
  switch (action.type) {
    case AppActionTypes.INIT: {
      return initialState
    }
    case UserActionTypes.CREATE_USER_LOADING: {
      newState.loading.userData = true
      return newState
    }
    case UserActionTypes.SET_USER:
      newState.userData = action.payload || newState.userData
      return newState
    case UserActionTypes.SET_VALID_USER:
      newState.isValidUser = action.payload
      return newState
    case UserActionTypes.SET_SPECIFIC_USER:
      newState.specificUser = action.payload
      return newState
    case UserActionTypes.SET_SAVING_USER_INFO:
      newState.loading.userInfo = action.payload
      return newState
    case UserActionTypes.UPDATE_USER:
      const key = action.payload.key as keyof UserData
      const userdata = _.cloneDeep(state.userData)
      userdata[key] = action.payload.value as never
      newState.userData = userdata
      newState.loading.userData = false
      return newState
    
    case UserActionTypes.UPDATE_USER_BUSINESS_DISCOUNT:
      newState.userData.user_discounts = action.payload;
      return newState

    case UserActionTypes.SET_USE_BATHROOM_LOADING:
      newState.loading.useBathroom = action.payload
      return newState

    case UserActionTypes.SET_PROFILE_PICTURE:
      const userData = _.cloneDeep(state.userData)
      userData.user_info = { ...userData.user_info, image_url: action.payload }
      newState.userData = userData
      return newState
    case UserActionTypes.SET_SUBSCRIPTION_DETAILS:
      newState.stripeDetails = action.payload
      return newState
    case UserActionTypes.SET_BUSINESS_INFO:
      newState.businessInfo = action.payload
      return newState
    case UserActionTypes.SET_PRICE_MODEL:
      if (newState.stripeDetails) {
        newState.stripeDetails.price_model = action.payload
      }
      return newState
    case UserActionTypes.SET_USER_FLAGGING:
      newState.flagDetails = action.payload
      return newState
    case UserActionTypes.SET_PRICE_ESTIMATE:
      newState.estimateToPay = action.payload
      return newState
    case UserActionTypes.SET_BUSINESS_TYPES: {
      newState.businessTypes = action.payload
      return newState
    }
    // case UserActionTypes.SET_USER_LOGIN_DATA:
    // 	newState.loginData = action.payload;
    // 	return newState;
    case UserActionTypes.SEND_CONTACT_US_INFO_SUCESS:
      newState.isConatctInfoUpdatedSucess = action.payload
      return newState
    case UserActionTypes.SAVE_LOGIN_CHECK_DATA:
      newState.loginCheckUserTypeData.mobile_number = action.payload
        .mobile_number
        ? action.payload.mobile_number
        : ''
      newState.loginCheckUserTypeData.email = action.payload.email
        ? action.payload.email
        : ''
      return newState
    case UserActionTypes.ZIP_CODE_ELIGIBILE_STATUS:
      newState.isZipCodeEligible = action.payload
      return newState
    case UserActionTypes.CLEAR_LOGIN_CHECK_DATA:
      newState.loginCheckUserTypeData.mobile_number = ''
      newState.loginCheckUserTypeData.email = ''
      return newState
    case UserActionTypes.GET_PRODUCT_LIST:
      // let's reset the product list before dispatching the getProductList()
      return {
        ...newState,
        productList: {
          platform: { monthly: [], yearly: [] },
          app: { monthly: [], yearly: [] },
        },
      }
    case UserActionTypes.SET_PRODUCT_LIST:
      return {
        ...newState,
        productList: action.payload
      }
    case UserActionTypes.SET_USER_BUSINESS_STRIPE:
      return {
        ...newState,
        userBusinessStripeData: action.payload
      }
    case UserActionTypes.SET_USER_RETRIEVE_SUBSCRIPTION:
      return {
        ...newState,
        userRetrieveSubscriptionData: action.payload
      }
    case UserActionTypes.SET_BATHROOM_CHALLENGE:
      newState.bathroomData = action.payload;
      return newState;
    case UserActionTypes.SET_BATHROOM_FETCH:
      newState.bathroomFetchError = true;
      return newState;
    case UserActionTypes.SET_STRIPE_ONBOARDING_STATUS:
      return {
        ...newState,
        stripeOnboardingStatus: action.payload
      }
    case UserActionTypes.SET_SPECIFIC_PRODUCT:
      return {
        ...newState,
        limitations: action.payload
      }
    case UserActionTypes.SET_API_ERROR:
      return {
        ...newState,
        isApiError: action.payload
      }
    case UserActionTypes.SET_SUGGESTION_USERNAME:
      return {
        ...newState,
        sugesstionUsername: action.payload
      }
    case UserActionTypes.LOADING_START:
      newState.loading.infoData = true
      return newState
    case UserActionTypes.LOADING_STOP:
      newState.loading.infoData = false
      return newState
    case UserActionTypes.SET_AFFILIATE:
      return {
        ...newState,
        affiliateData: action.payload
      }
    case UserActionTypes.SET_GENERATE_AFFILIATE_LINK:
      return {
        ...newState,
        affiliateLink: action.payload
      }
    case UserActionTypes.SAVE_RETRIEVE_USER_CHATS:
      return {
        ...newState,
        chatData: action.payload
      }
    case UserActionTypes.SAVE_SPECIFIC_MESSAGES:
      const {messages, pagination, chatroomid} = action.payload;
      return {
        ...newState,
        specificChatData: {
          messages: state.specificChatData.messages
          ? [...newState.specificChatData.messages, ...messages]
          : messages,
        chatroomid,
        pagination,
        }
      }
    case UserActionTypes.CLEAR_SPECIFIC_CHAT_DATA:
      return {
        ...newState,
        specificChatData: {
          messages: [],
          chatroomid: null,
          pagination: null,
        },
      };
    case UserActionTypes.SET_CHATROOM_MESSAGE:
      const newMessage = action.payload.messages.slice(-1)[0];
      const updatedChatData = newState.chatData.map((chat : any) =>
        chat.chatRoomId === action.payload.chatRoomId
          ? {
              ...chat,
              recentMessage: newMessage
            }
          : chat
      );

      return {
        ...newState,
        chatData: updatedChatData,
        specificChatRoomData: newMessage, // Update specificChatRoomData
      };
    case UserActionTypes.SET_SUBSCRIPTION_TYPES:
      return {
        ...newState,
        subscriptionTypes: action.payload
      }
    case UserActionTypes.SET_ALL_AFFILIATES:
      return {
        ...newState,
        allAffiliateData: action.payload
      }
    case UserActionTypes.SET_SESSION_DATA:
      return {
        ...newState,
        sessionData: action.payload
      }
    case UserActionTypes.SET_SUPER_ADMIN_AFFILIATE_DATA:
      return {
        ...newState,
        superAdminAffiliateData: action.payload,
      }
    default: {
      return state
    }
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as userReducer }
