import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  Checkbox,
  Grid,
  TextField,
  InputAdornment,
  Box,
  Tooltip,
} from '@mui/material'
import { BathtubOutlined, StorefrontOutlined, HelpOutline } from '@mui/icons-material';
import { makeStyles } from '@mui/styles'
import UIConstants from '../../../theme/constants/ui-constants'
import { CheckInput } from '../../../theme/components/input/CheckBox'
import { useTranslation } from 'react-i18next'
import { CustomButton } from '../../../theme/components/buttons/Button'
import { InputText } from '../../../theme/components/input/TextField'
import { useCallback, useEffect, useState } from 'react'
import DatePicker from '@mui/lab/DatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import background from '../../../assets/images/background.png'
import logo from '../../../assets/images/logo.svg'
import mobileLogo from '../../../assets/images/logo_mobile.svg'
import {
  CreateActionModel,
  SaveBusinessInfoActionModel,
  SaveUserInfoActionModel,
  SaveBusinessActionModel,
  UserData,
} from '../../../store/app/user/types'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import _ from 'lodash'
import LocationSelector from '../../../theme/components/input/LocationSelector'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchBusinessTypes,
  getProductList,
} from '../../../store/app/user/actions'
import { ApplicationState } from '../../../store'
import { PhoneInput } from '../../../theme/components/input/PhoneField'
import SubscriptionModal from '../../../app.v2/component/SubscriptionModal'
import { subYears, startOfDay } from 'date-fns'
import toaster from '../../../theme/components/Toaster';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: UIConstants.rootBgColor,
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'contain',
    backgroundPosition: 'center top',
    minHeight: `100vh`,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      backgroundSize: 'cover',
    },
  },
  logodiv: {
    minHeight: '9rem',
    position: 'fixed',
    top: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: '15%',
      position: 'unset',
      top: 'unset',
      width: 'auto',
    },
  },
  logo: {
    height: '40px',
    [theme.breakpoints.up('sm')]: {
      height: '70px',
      marginLeft: '15%',
    },
  },
  signupBox: {
    background: UIConstants.rootBgColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: `0 ${theme.spacing(3)}`,
    position: 'relative',
    top: '4rem',
    height: 'calc(100vh - 9rem)',
    [theme.breakpoints.up('sm')]: {
      position: 'unset',
      maxWidth: '400px',
      height: '100%',
      margin: 'auto',
      top: 'unset',
      padding: `${theme.spacing(3)}`,
    },
  },
  heading: {
    margin: `${theme.spacing(1)} ${theme.spacing(4)}`,
    '& .mainHeading': {
      fontWeight: 400,
      fontSize: '18px',
      fontFamily: UIConstants.appFont,
      color: UIConstants.primaryBgColor,
    },
    '& .subHeading': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '27px',
      fontFamily: UIConstants.appFont,
      color: UIConstants.primaryBgColor,
      opacity: 0.5,
    },
  },
  signUpInputBox: {
    minWidth: '250px',
    margin: `${theme.spacing(4)} 0`,
    width: '100%',
  },
  formControl: {
    margin: `${theme.spacing(3)} ${theme.spacing(1)}`,
    display: 'flex',
    // flexDirection: 'column-reverse',
  },
  becomeAHost: {
    color: UIConstants.primaryBgColor,
    margin: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  termsAndCondition: {
    color: UIConstants.primaryBgColor,
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      opacity: '0.5',
    },
  },
  labelOtherSignup: {
    color: UIConstants.primaryBgColor,
    fontSize: '14px',
    opacity: '0.5',
  },
  inputBorderPrimary: {
    '&.MuiInputBase-root': {
      '& > fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
    },
  },
  suggestion: {
    marginTop: '-15px',
    fontSize: '12px',
    fontStyle: 'italic',
    color: UIConstants.suggestion,
  },
  sectionHeader: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  },
}))

interface PersonalInfoProps {
  userData: UserData
  saveUserInfo: (user: SaveUserInfoActionModel) => void
  createUser: (model: CreateActionModel) => void
  saveBusinessInfo: (data: SaveBusinessInfoActionModel) => void
  saveBusiness: (data: SaveBusinessActionModel) => void
}

interface IFormInput {
  firstName: string
  lastName: string
  dob: Date
  email: string
  mobile_number: string
  subscribeMessage: boolean
  homeAddress?: string
  business_industry?: string
  business_name?: string
  businessAddress?: any
  username: string
  numberOfStore: number
  singleBathrooms: number
  groupBathrooms: number
  isGroupBathroom: boolean
  // New fields for bathroom breakdowns
  private_male: number
  private_female: number
  private_family: number
  private_unisex: number
  group_male: number
  group_female: number
  group_family: number
  group_unisex: number

}

const isValidEmail = (email: string) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

export default function PersonalInfo({
  userData,
}: PersonalInfoProps) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const [selectedLocationData, setSelectedLocationData] = useState<string>('')
  const [homeAddress, setHomeAddress] = useState<string>('')
  const [creatingUserAccount, setCreatingUserAccount] = useState<any>(null)
  const [isBathroomExceed, setIsBathroomExceed] = useState<boolean>(false)
  const [formData, setFormData] = useState<any>('')
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const { isApiError, sugesstionUsername, businessTypes, productList } = useSelector((state: ApplicationState) => state.user)
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const appLogo = matches ? logo : mobileLogo
  const countries = process.env.REACT_APP_SUPPORTED_COUNTRIES?.split(',')
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    mode: 'all',
    defaultValues: {
      isGroupBathroom: false,
      groupBathrooms: 0,
      singleBathrooms: 0,
      private_male: 0,
      private_female: 0,
      private_family: 0,
      private_unisex: 0,
      group_male: 0,
      group_female: 0,
      group_family: 0,
      group_unisex: 0,
    }
  })


  // Watch for a business industry selection
  const selectedBusinessIndustry = watch('business_industry');
  const isGroupBathroomChecked = watch('isGroupBathroom');
  const singleBathrooms = watch('singleBathrooms');
  const groupBathrooms = watch('groupBathrooms');
  const privateValues = watch(['private_male', 'private_female', 'private_family', 'private_unisex']);
  const privateSum = privateValues.reduce((sum, val) => sum + (val || 0), 0);
  const groupValues = watch(['group_male', 'group_female', 'group_family', 'group_unisex']);
  const groupSum = groupValues.reduce((sum, val) => sum + (val || 0), 0);

  const affiliateCode = localStorage.getItem('affiliate_code') ?? '';

  const isAtLeast18 = (dob: number | Date) => {
    const today = startOfDay(new Date());
    const dobDate = startOfDay(dob);
    const eighteenYearsAgo = subYears(today, 18);
    return dobDate <= eighteenYearsAgo;
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    // Convert values to numbers explicitly
    const singleBathroomsNum = Number(data.singleBathrooms || 0);
    const groupBathroomsNum = Number(data.groupBathrooms || 0);
    const privateSum = Number(data.private_male || 0) + 
                      Number(data.private_female || 0) + 
                      Number(data.private_family || 0) + 
                      Number(data.private_unisex || 0);
    const groupSum = Number(data.group_male || 0) + 
                    Number(data.group_female || 0) + 
                    Number(data.group_family || 0) + 
                    Number(data.group_unisex || 0);
  
    // Debug logging
    console.log('singleBathroomsNum:', singleBathroomsNum, 'privateSum:', privateSum);
    console.log('groupBathroomsNum:', groupBathroomsNum, 'groupSum:', groupSum);
  
    // Validation for private bathrooms
    if (singleBathroomsNum >= 1) {
      if (privateSum < singleBathroomsNum) {
        toaster.warning(`Please allocate all private bathrooms. Current sum (${privateSum}) is less than total (${singleBathroomsNum}).`);
        setIsBathroomExceed(false)
        return;
      }
      if (privateSum > singleBathroomsNum) {
        toaster.warning(`The sum of private bathroom types (${privateSum}) exceeds the total (${singleBathroomsNum}).`);
        setIsBathroomExceed(true);
        return;
      }
    }
  
    // Validation for group bathrooms
    if (data.isGroupBathroom && groupBathroomsNum >= 1) {
      if (groupSum < groupBathroomsNum) {
        toaster.warning(`Please allocate all group bathrooms. Current sum (${groupSum}) is less than total (${groupBathroomsNum}).`);
        setIsBathroomExceed(false)
        return;
      }
      if (groupSum > groupBathroomsNum) {
        toaster.warning(`The sum of group bathroom types (${groupSum}) exceeds the total (${groupBathroomsNum}).`);
        setIsBathroomExceed(true);
        return;
      }
    }
    setIsBathroomExceed(false)
  
    const finalData = {
      ...data,
      groupBathrooms: data.isGroupBathroom ? groupBathroomsNum : 0,
    };
  
    setFormData(finalData);
    dispatch(
      getProductList(
        singleBathroomsNum,
        groupBathroomsNum,
        Number(data.numberOfStore || 0),
        data.homeAddress || '',
        data.businessAddress || '',
      )
    );
    console.log('Opening subscription modal');
    setSubscriptionModalOpen(true);
  };

  const onClose = useCallback(() => {
    setSubscriptionModalOpen(false);
  }, []);

  useEffect(() => {
    if (!isGroupBathroomChecked) {
      // Reset group bathrooms when checkbox is unchecked
      setValue('groupBathrooms', 0)
    }
  }, [isGroupBathroomChecked, setValue])

  const userDatum = () => {
    const data = {
      ...userData,
      mobile_number: formData.mobile_number,
      email: formData.email,
    }
    return data;
  }

  const userInformationData = () => {
    const user = _.cloneDeep(userData)
    user.email = formData.email
    user.user_info = {
      ...user.user_info,
      first_name: formData.firstName,
      last_name: formData.lastName,
      date_of_birth: formData.dob,
      receive_msg: formData.subscribeMessage,
      email: formData.email,
      home_address: homeAddress,
      username: formData.username,
    }

    const data = {
      user_info: user.user_info,
      user_id: user.id,
      user_type: user.user_type,
      email: formData.email,
      mobile_number: formData.mobile_number,
      language: 'en',
      country_code: userData.country_code,
      username: formData.username,
      login_type: userData.user_info !== undefined ? 'email' : 'phone',
    }

    return data;
  }

  const businessInformationData = () => {
    const user = _.cloneDeep(userData)
    const data = {
      businessName: formData.business_name,
      businessIndustry: formData.business_industry,
      location: selectedLocationData,
      user_id: user.id
    }
    return data;
  }

  const businessData = () => {
    const user = _.cloneDeep(userData)
    const data = {
      business_name: formData.business_name,
      business_address: selectedLocationData,
      user_id: user.id,
      numberOfBathroom: formData.numberOfBathroom,
      numberOfStore: formData.numberOfStore,
      isGroupBathroom: formData.isGroupBathroom
    }

    return data;
  }

  const affiliateData = () => {
    const data = {
      affiliateCode: affiliateCode ?? ''
    }
    return data;
  }

  const bathroomData = () => {
    const data = {
      single_bathroom: formData.singleBathrooms,
      group_bathroom: formData.isGroupBathroom ? formData.groupBathrooms : 0,
      single_male: formData.private_male,
      single_female: formData.private_female,
      single_family: formData.private_family,
      single_unisex: formData.private_unisex,
      group_male: formData.group_male,
      group_female: formData.group_female,
      group_family: formData.group_family,
      group_unisex: formData.group_unisex
    }
    return data;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (userData) {
      dispatch(fetchBusinessTypes())
    }
  }, [userData, dispatch])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <div className={classes.logodiv}>
            {' '}
            <img src={appLogo} alt={t('appName')} className={classes.logo} />
          </div>
          <Paper elevation={matches ? 3 : 0} className={classes.signupBox}>
            <div className={classes.heading}>
              <Typography className="mainHeading">
                {t('personalInfo.mainHeading')}
              </Typography>
            </div>
            <div className={classes.signUpInputBox}>
              <div className={classes.formControl}>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue={userData.user_info?.first_name}
                  render={({ field }) => (
                    <InputText
                      fullWidth
                      variant="outlined"
                      error={!!errors?.firstName}
                      size="small"
                      label={t('personalInfo.firstNameLabel')}
                      {...register('firstName', {
                        required: t('signUp.required') as string,
                      })}
                      {...field}
                      helperText={errors?.firstName?.message}
                    />
                  )}
                />
              </div>
              <div className={`${classes.formControl} flex flex-col`}>
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue={userData.user_info?.last_name}
                  render={({ field }) => (
                    <InputText
                      fullWidth
                      variant="outlined"
                      error={!!errors?.lastName}
                      size="small"
                      label={t('personalInfo.lastNameLabel')}
                      {...register('lastName', {
                        required: t('signUp.required') as string,
                      })}
                      {...field}
                      helperText={errors?.lastName?.message}
                    />
                  )}
                />
                <span className="text-subText text-xs">
                  Make sure it matches with your government ID
                </span>
              </div>
              <div className={`${classes.formControl} flex flex-col`}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    name="dob"
                    control={control}
                    defaultValue={new Date()}
                    rules={{
                      validate: (value) => isAtLeast18(value) || 'You must be at least 18 years old'
                    }}
                    render={({ field }) => (
                      <DatePicker
                        disableFuture
                        label={t('personalInfo.birthDateLabel')}
                        openTo="year"
                        views={['year', 'month', 'day']}
                        value={field.value}
                        onChange={field.onChange}
                        renderInput={(params: any) => (
                          <InputText
                            fullWidth
                            variant="outlined"
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
                {errors.dob && <span className="text-red-500 text-xs">{errors.dob.message}</span>}
              </div>
              <div className={classes.formControl}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={userData.user_info?.email}
                  render={({ field }) => (
                    <InputText
                      disabled={userData.user_info !== undefined}
                      fullWidth
                      variant="outlined"
                      error={!!errors?.email}
                      size="small"
                      label={t('personalInfo.eMailLabel')}
                      {...register('email', {
                        required: t('signUp.required') as string,
                        validate: {
                          isValidEmail: (value) =>
                            isValidEmail(value)
                              ? true
                              : (t('personalInfo.invalidEmailId') as string),
                        },
                      })}
                      {...field}
                      helperText={errors?.email?.message}
                    />
                  )}
                />
              </div>
              <div className={classes.formControl}>
                <Controller
                  name="username"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputText
                      fullWidth
                      variant="outlined"
                      error={!!errors?.username}
                      size="small"
                      label={t('personalInfo.username')}
                      {...register('username', {
                        required: t('signUp.required') as string,
                      })}
                      {...field}
                      helperText={errors?.username?.message}
                    />
                  )}
                />
              </div>
              {sugesstionUsername && sugesstionUsername.length > 0 &&
                <div className={classes.formControl}>
                  <p className={classes.suggestion}><b>suggestion</b>: {sugesstionUsername.join(', ')}</p>
                </div>
              }
              <div className={classes.formControl}>
                <PhoneInput
                  onlyCountries={countries}
                  fullWidth
                  label="Phone Number"
                  variant="outlined"
                  disabled={userData?.mobile_number !== undefined}
                  defaultValue={userData?.mobile_number}
                  size="small"
                  defaultCountry={'us'}
                  control={control}
                  name={'mobile_number'}
                  error={errors?.mobile_number}
                  helperText={errors?.mobile_number?.message}
                ></PhoneInput>
              </div>
              <div className={classes.formControl}>
                <Controller
                  name="homeAddress"
                  control={control}
                  defaultValue={''}
                  rules={{
                    required: 'Location required' as string,
                  }}
                  render={({ field }) => (
                    <LocationSelector
                      {...field}
                      label="Home Address"
                      setSelectedLocationData={setHomeAddress}
                      size="small"
                      error={errors?.homeAddress}
                      helperText={errors?.homeAddress?.message}
                    />
                  )}
                />
              </div>
              {userData.user_type === 1 && (
                <>
                  <hr />
                  <div className={classes.formControl}>
                    <Controller
                      name="business_industry"
                      control={control}
                      render={({ field }) => (
                        <FormControl size="small" fullWidth>
                          <InputLabel
                            sx={{
                              color: UIConstants.primaryBgColor,
                              '&.Mui-focused': {
                                color: UIConstants.primaryBgColor,
                              },
                            }}
                          >
                            Business Industry
                          </InputLabel>
                          <Select
                            {...field}
                            label="Business Type"
                            {...register('business_industry', {
                              required: 'This field is required',
                            })}
                            error={!!errors?.business_industry}
                            className={classes.inputBorderPrimary}
                          >
                            {businessTypes?.map((business) => (
                              <MenuItem value={business.name} key={business.id}>
                                {business.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </div>
                  <div className={classes.formControl}>
                    <Controller
                      name="business_name"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          fullWidth
                          variant="outlined"
                          error={!!errors?.business_name}
                          size="small"
                          label="Business Name"
                          {...register('business_name', {
                            required: 'Business name is required' as string,
                          })}
                          {...field}
                          helperText={errors?.business_name?.message}
                        />
                      )}
                    />
                  </div>
                  <div className={classes.formControl}>
                    <Controller
                      name="businessAddress"
                      control={control}
                      defaultValue={''}
                      rules={{
                        required: 'Location required' as string,
                      }}
                      render={({ field }) => (
                        <LocationSelector
                          {...field}
                          label="Business Address"
                          setSelectedLocationData={setSelectedLocationData}
                          size="small"
                          error={errors?.businessAddress}
                          helperText={errors?.businessAddress?.message}
                        />
                      )}
                    />
                  </div>
                  {selectedBusinessIndustry && (
                    <Paper elevation={2} style={{
                      padding: '24px',
                      marginTop: '24px',
                      borderLeft: `4px solid ${theme.palette.primary.main}`,
                      borderRadius: '8px'
                    }}>
                      <div className={classes.sectionHeader}>
                        <Typography variant="h6" component="h2">
                          Bathroom Configuration
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Help us recommend the best cleaning solution for your facilities
                        </Typography>
                      </div>

                      <Grid container spacing={3}>
                        {/* Number of Stores */}
                        <Grid item xs={12} md={12}>
                          <Controller
                            name="numberOfStore"
                            control={control}
                            defaultValue={0}
                            rules={{ required: 'Number of stores is required' }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                variant="outlined"
                                size="small"
                                type="number"
                                label="Number of Stores"
                                error={!!errors?.numberOfStore}
                                helperText={errors?.numberOfStore?.message}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <StorefrontOutlined fontSize="small" color="action" />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Grid>
                        {/* Private/Total Bathrooms */}
                        <Grid item xs={12} md={12}>
                          <Controller
                            name="singleBathrooms"
                            control={control}
                            defaultValue={0}
                            rules={{
                              required: 'Number of bathrooms is required',
                              min: { value: 0, message: 'Cannot be negative' }
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                variant="outlined"
                                size="small"
                                type="number"
                                label={isGroupBathroomChecked ? "Private Bathrooms" : "Total Bathrooms"}
                                error={!!errors?.singleBathrooms}
                                helperText={errors?.singleBathrooms?.message}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <BathtubOutlined fontSize="small" color="action" />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Grid>
                        {/* Private Bathroom Breakdown */}
                        {singleBathrooms >= 1 && (
                          <Grid container spacing={2} style={{ marginTop: '16px', paddingLeft: '16px' }}>
                            <Grid item xs={12}>
                              <Typography variant="subtitle1">Private Bathroom Breakdown</Typography>
                            </Grid>
                            <Grid item xs={6} md={12}>
                              <Controller
                                name="private_male"
                                control={control}
                                defaultValue={0}
                                rules={{ min: { value: 0, message: 'Cannot be negative' } }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    label="Male"
                                    error={!!errors?.private_male}
                                    helperText={errors?.private_male?.message}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6} md={12}>
                              <Controller
                                name="private_female"
                                control={control}
                                defaultValue={0}
                                rules={{ min: { value: 0, message: 'Cannot be negative' } }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    label="Female"
                                    error={!!errors?.private_female}
                                    helperText={errors?.private_female?.message}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6} md={12}>
                              <Controller
                                name="private_family"
                                control={control}
                                defaultValue={0}
                                rules={{ min: { value: 0, message: 'Cannot be negative' } }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    label="Family"
                                    error={!!errors?.private_family}
                                    helperText={errors?.private_family?.message}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6} md={12}>
                              <Controller
                                name="private_unisex"
                                control={control}
                                defaultValue={0}
                                rules={{ min: { value: 0, message: 'Cannot be negative' } }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    label="Unisex"
                                    error={!!errors?.private_unisex}
                                    helperText={errors?.private_unisex?.message}
                                  />
                                )}
                              />
                            </Grid>
                            {isBathroomExceed ? (
                              <Grid item xs={12}>
                                <Typography color="error">
                                  The sum of private bathroom types exceeds the total ({singleBathrooms}).
                                </Typography>
                              </Grid>
                            ): null}
                          </Grid>
                        )}
                        {/* Group Bathroom Checkbox */}
                        <Grid item xs={12}>
                          <Controller
                            name="isGroupBathroom"
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={field.value}
                                    onChange={(e) => field.onChange(e.target.checked)}
                                  />
                                }
                                label={
                                  <Box display="flex" alignItems="center" gap={1}>
                                    <Typography>We have shared/group bathrooms</Typography>
                                    <Tooltip title="Group bathrooms are used by multiple people across different locations">
                                      <HelpOutline fontSize="small" color="action" />
                                    </Tooltip>
                                  </Box>
                                }
                              />
                            )}
                          />
                        </Grid>
                        {isGroupBathroomChecked && (
                          <>
                            <Grid item xs={12} md={12}>
                              <Controller
                                name="groupBathrooms"
                                control={control}
                                defaultValue={0}
                                rules={{
                                  validate: value => {
                                    if (isGroupBathroomChecked) {
                                      return (value >= 1) || 'At least 1 group bathroom required';
                                    }
                                    return true;
                                  }
                                }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    label="Shared/Group Bathrooms"
                                    error={!!errors?.groupBathrooms}
                                    helperText={errors?.groupBathrooms?.message}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <BathtubOutlined fontSize="small" color="action" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            {/* Group Bathroom Breakdown */}
                            {groupBathrooms >= 1 && (
                              <Grid container spacing={2} style={{ marginTop: '16px', paddingLeft: '16px' }}>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">Group Bathroom Breakdown</Typography>
                                </Grid>
                                <Grid item xs={6} md={12}>
                                  <Controller
                                    name="group_male"
                                    control={control}
                                    defaultValue={0}
                                    rules={{ min: { value: 0, message: 'Cannot be negative' } }}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        label="Male"
                                        error={!!errors?.group_male}
                                        helperText={errors?.group_male?.message}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={6} md={12}>
                                  <Controller
                                    name="group_female"
                                    control={control}
                                    defaultValue={0}
                                    rules={{ min: { value: 0, message: 'Cannot be negative' } }}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        label="Female"
                                        error={!!errors?.group_female}
                                        helperText={errors?.group_female?.message}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={6} md={12}>
                                  <Controller
                                    name="group_family"
                                    control={control}
                                    defaultValue={0}
                                    rules={{ min: { value: 0, message: 'Cannot be negative' } }}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        label="Family"
                                        error={!!errors?.group_family}
                                        helperText={errors?.group_family?.message}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={6} md={12}>
                                  <Controller
                                    name="group_unisex"
                                    control={control}
                                    defaultValue={0}
                                    rules={{ min: { value: 0, message: 'Cannot be negative' } }}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        label="Unisex"
                                        error={!!errors?.group_unisex}
                                        helperText={errors?.group_unisex?.message}
                                      />
                                    )}
                                  />
                                </Grid>
                                {isBathroomExceed ? (
                                  <Grid item xs={12}>
                                    <Typography color="error">
                                      The sum of group bathroom types ({groupSum}) exceeds the total ({groupBathrooms}).
                                    </Typography>
                                  </Grid>
                                ): null}
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <Typography variant="body2" color="textSecondary">
                                Shared bathrooms are facilities used by multiple people across different stores/branches
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Paper>
                  )}
                </>
              )}
              <div className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Controller
                      name="subscribeMessage"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <CheckInput
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  }
                  label={t('personalInfo.receiveMessage') as string}
                  className={classes.termsAndCondition}
                />
              </div>
              <div className={classes.formControl}>
                <CustomButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={!isValid}
                >
                  {creatingUserAccount && !isApiError ? (
                    <CircularProgress size={25} />
                  ) : (
                    t('personalInfo.continue')
                  )}
                </CustomButton>
              </div>
            </div>
          </Paper>
        </div>
      </form>
      {subscriptionModalOpen && isValid && productList && (
        <SubscriptionModal
          open={subscriptionModalOpen}
          onClose={() => onClose()}
          products={productList}
          registrationData={formData}
          userData={userDatum()}
          userInfoData={userInformationData()}
          businessInfoData={businessInformationData()}
          businessData={businessData()}
          affiliateData={affiliateData()}
          bathroomData={bathroomData()}
        />
      )}
    </>
  )
}
